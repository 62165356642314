import { Epg, Layout } from 'planby-mit';
import { ChannelItem } from './channel-item.tsx';
import { EpgItem } from './epg-item.tsx';
import { Timeline } from './timeline.tsx';
import { useEpgResources } from './use-epg-resources.ts';
import { ClientOnly } from 'remix-utils/client-only';
import { ErrorBoundary } from 'react-error-boundary';
import { EPGLoadingSkeleton } from '../cell-components/skeletons.tsx';
import { type Feed } from '~/services/layout/index.ts';

export function EPG({
  uiComponent,
  feed,
}: {
  uiComponent: any;
  feed: Feed;
}): JSX.Element {
  const itemHeight: number = uiComponent?.styles?.channel_item_height || 80;
  const channelSidebarWidth: number =
    uiComponent?.styles?.channel_sidebar_width || 100;

  const { getEpgProps, getLayoutProps, isLoading } = useEpgResources({
    uiComponent,
    feed,
    itemHeight,
    channelSidebarWidth,
  });

  const numberOfChannels: number = feed?.entry?.length || 0;
  if (numberOfChannels === 0) return <></>;

  const containerHeight: number = numberOfChannels * itemHeight;

  const style: React.CSSProperties = {
    height: `${containerHeight + 100}px`,
    width: '100%',
  };

  const epgLoadingSkeleton: JSX.Element = (
    <EPGLoadingSkeleton
      loadingSkeletonItemHeight={itemHeight}
      channelSidebarWidth={channelSidebarWidth}
      numChannels={numberOfChannels}
      uiComponentId={uiComponent.id}
    />
  );

  if (isLoading) return epgLoadingSkeleton;

  return (
    <ClientOnly fallback={epgLoadingSkeleton}>
      {() => (
        <ErrorBoundary fallback={<></>}>
          <div
            style={style}
            className={`component-${uiComponent.id} mb-component-b ml-component-l mr-component-r mt-component-t bg-component pb-component-b pl-component-l pr-component-r pt-component-t`}
            data-testid={`epg-container-${uiComponent.id}`}
          >
            <Epg {...getEpgProps()}>
              <Layout
                {...getLayoutProps()}
                renderProgram={({ program, ...rest }) => (
                  <EpgItem
                    key={program.data.id}
                    program={program}
                    uiComponent={uiComponent}
                    {...rest}
                  />
                )}
                renderChannel={({ channel }) => (
                  <ChannelItem key={channel.uuid} channel={channel} />
                )}
                renderTimeline={(props) => <Timeline {...props} />}
              />
            </Epg>
          </div>
        </ErrorBoundary>
      )}
    </ClientOnly>
  );
}
