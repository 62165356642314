import get from 'lodash.get';
import {
  type ResponsiveSources,
  getCellAssetSources,
  getCellBreakpointValues,
} from './power-cell-getters';
import { ResponsiveImage } from './responsive-image';
import type { FeedEntry } from '~/services/layout/index';

export function LockBadge({
  entry,
  assets,
}: {
  entry: FeedEntry;
  assets: any;
}): JSX.Element {
  const lockBadgesImages = getLockBadgeAssets({ entry, assets });

  return (
    <ResponsiveImage
      imageSources={lockBadgesImages}
      altText={entry?.title && `${entry?.title} lock badge`}
      classNames="z-[3] absolute lock-badge-pos w-lock-badge h-lock-badge mt-lock-badge-t mr-lock-badge-r mb-lock-badge-b ml-lock-badge-l"
    />
  );
}

function getLockBadgeAssets({
  entry,
  assets,
}: {
  entry: FeedEntry;
  assets: any;
}): ResponsiveSources {
  const lockBadges: ResponsiveSources = getCellAssetSources({
    assets,
    assetKey: 'locked_badge',
    assetSwitchKey: 'lock_badge_switch',
  });

  const unlockedBadges: ResponsiveSources = getCellAssetSources({
    assets,
    assetKey: 'unlocked_badge',
    assetSwitchKey: 'lock_badge_switch',
  });

  const lockBadgeDataKeys: ResponsiveSources = getCellBreakpointValues({
    config: assets,
    key: 'lock_badge_data_key',
  });

  const isMobileUnlockBadge: boolean | undefined = get(
    entry,
    lockBadgeDataKeys.mobile as string
  );
  const isTabletUnlockBadge: boolean | undefined = get(
    entry,
    lockBadgeDataKeys.tablet as string
  );
  const isDesktopUnlockBadge: boolean | undefined = get(
    entry,
    lockBadgeDataKeys.desktop as string
  );
  const isLargeDesktopUnlockBadge: boolean | undefined = get(
    entry,
    lockBadgeDataKeys['large-desktop'] as string
  );

  return {
    mobile: isMobileUnlockBadge ? unlockedBadges.mobile : lockBadges.mobile,
    tablet: isTabletUnlockBadge ? unlockedBadges.tablet : lockBadges.tablet,
    desktop: isDesktopUnlockBadge ? unlockedBadges.desktop : lockBadges.desktop,
    'large-desktop': isLargeDesktopUnlockBadge
      ? unlockedBadges['large-desktop']
      : lockBadges['large-desktop'],
  };
}
