import { Form, useLoaderData, useSearchParams } from '@remix-run/react';
import type { loader as rootLoader } from '~/routes/_index.tsx';
import debounce from 'lodash.debounce';
import { useRef, useState } from 'react';
import { track } from '~/services/analytics/index.client';
import { validateSearchQuery } from '~/utils/validations.ts';
import { getTranslations } from '~/services/localization';
import { twMerge } from 'tailwind-merge';
import { PreloadImage } from '~/components/preload-image';

/**
 * SearchV2 Screen
 * Plugin identifier: search_v2
 */
export function SearchV2(): JSX.Element {
  const {
    searchScreenId,
    pathname,
    autofocusEnabled,
    q,
    searchScreenStyles,
    onChange,
    resetSearchParams,
    showCancelIconButton,
    inputRef,
    honeypotInputRef,
    translations,
  } = useSearch();

  const searchIconSwitch: boolean = searchScreenStyles?.search_icon_switch;
  const searchIconFocusedSwitch: boolean =
    searchScreenStyles?.search_icon_focused_switch;

  const searchPlaceholder: string = translations?.placeholder_message || '';

  const clearIconHoverSwitch: boolean =
    searchScreenStyles?.clear_icon_hover_switch;

  return (
    <section
      role="search"
      aria-label="Search Component"
      className={`search-v2-${searchScreenId} search-bar-container-classes inset-x-0 z-40`}
    >
      <Form method="get" action={pathname}>
        <div className="group relative">
          {searchIconFocusedSwitch && (
            <PreloadImage tailwindImageClass="search-icon-focused-asset" />
          )}

          <div className="absolute inset-y-0 left-0 flex items-center bg-cover bg-center">
            {searchIconSwitch && (
              <div
                className={`search-icon-classes bg-search-icon-asset bg-contain bg-center bg-no-repeat ${
                  searchIconFocusedSwitch
                    ? 'group-focus-within:bg-search-icon-focused-asset'
                    : ''
                }`}
                aria-label="search icon"
              />
            )}
          </div>

          {clearIconHoverSwitch && (
            <PreloadImage tailwindImageClass="search-clear-icon-asset" />
          )}

          {clearIconHoverSwitch && (
            <PreloadImage tailwindImageClass="search-clear_icon_hover_asset" />
          )}

          {showCancelIconButton && (
            <div
              className={`search-clear-icon-container-classes absolute inset-y-0 right-0 flex h-full items-center bg-transparent bg-cover bg-center`}
            >
              <div
                className={`search-clear-icon-classes cursor-pointer bg-search-clear-icon-asset bg-contain bg-center bg-no-repeat ${
                  clearIconHoverSwitch
                    ? 'hover:bg-search-clear_icon_hover_asset'
                    : ''
                }`}
                onClick={resetSearchParams}
                aria-label="cancel search and clear input"
                role="cancel"
              />
            </div>
          )}
          <input
            ref={honeypotInputRef}
            className="honeypot"
            type="text"
            tabIndex={-1}
            autoComplete="off"
            name="name"
          />
          <input
            ref={inputRef}
            autoFocus={autofocusEnabled}
            name="q"
            key={q}
            defaultValue={q}
            type="search"
            id="default-search"
            className={`
              group
              search-bar-classes
              search-label-classes
              w-full
              border-search_bar_border_color
              bg-search_bar_background_color
              font-search_label_font_family
              text-search-color
              caret-search-bar search-label-transform 
              placeholder:text-search-placeholder-color
              focus:border-search_bar_focused_border_color
              focus:bg-search_bar_focused_background_color
              focus:text-search-focused-color
              focus:outline-none
              `}
            placeholder={searchPlaceholder}
            onChange={onChange}
            autoComplete="off"
            onKeyDown={(e) => {
              const loadFallbackScreenOnEnter: boolean =
                e.key === 'Enter' &&
                inputRef?.current?.value === '' &&
                q !== '';

              if (!loadFallbackScreenOnEnter) return;

              resetSearchParams();
            }}
          />

          <div className="relative">
            <div
              className={twMerge(
                `search-bar-underline-classes absolute bottom-0 left-0 right-0 top-0 bg-search_bar_underline_bottom_color group-focus-within:bg-search_bar_focused_underline_bottom_color`
              )}
            ></div>
          </div>
        </div>
      </Form>
    </section>
  );
}

function useSearch(): {
  searchScreenId: string;
  pathname: string;
  autofocusEnabled: boolean;
  q: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  resetSearchParams: () => void;
  showCancelIconButton: boolean;
  inputRef: React.RefObject<HTMLInputElement>;
  honeypotInputRef: React.RefObject<HTMLInputElement>;
  searchScreenStyles: any;
  translations: any;
} {
  const { searchData, analyticsTrackData } = useLoaderData<typeof rootLoader>();
  const [searchParams, setSearchParams] = useSearchParams();

  const inputRef = useRef<HTMLInputElement>(null);
  const honeypotInputRef = useRef<HTMLInputElement>(null);

  const [showCancelIconButton, setShowCancelIconButton] = useState<boolean>(
    !!searchParams.get('q')
  );

  const {
    searchScreenStyles,
    localizations,
    minimumQueryLength,
    maximumQueryLength,
    searchScreenId,
    pathname,
    autofocusEnabled,
    q,
    debounce: searchInputDebounce,
  } = searchData as any;

  const translations = getTranslations(localizations);

  const onChange = debounce((event) => {
    const value = event.target.value;
    if (value) setShowCancelIconButton(true);

    if (honeypotInputRef.current?.value !== '') return;
    if (!validateSearchQuery(value)) return;
    if (value.length < minimumQueryLength || value.length > maximumQueryLength)
      return;

    track('search_executed', {
      ...analyticsTrackData,
      query_length: value?.length,
      query_text: value,
    });

    setSearchParams({ q: value });
  }, searchInputDebounce as number | undefined);

  const resetSearchParams = () => {
    if (!inputRef.current) return;
    track('search_canceled', {
      ...analyticsTrackData,
      query_length: inputRef.current.value?.length,
      query_text: inputRef.current.value,
    });
    inputRef.current.value = '';
    inputRef.current.focus();
    setShowCancelIconButton(false);
    setSearchParams({ q: '' });
  };

  return {
    searchScreenId,
    pathname,
    autofocusEnabled,
    q,
    onChange,
    resetSearchParams,
    showCancelIconButton,
    inputRef,
    honeypotInputRef,
    searchScreenStyles,
    translations,
  };
}
