import { twMerge } from 'tailwind-merge';

import type { FeedEntry } from '~/services/layout/index.ts';
import { getDuration } from '~/services/layout/layout';

function Runtime({
  entry,
  enabled,
  hero = false,
}: {
  entry: FeedEntry;
  enabled: boolean;
  hero?: boolean;
}): JSX.Element {
  if (!enabled) return <></>;

  const duration: string | undefined = getDuration(entry);

  if (!duration) return <></>;

  return (
    <div
      className={twMerge(
        'mb-runtime-b ml-runtime-l mr-runtime-r mt-runtime-t rounded-runtime-radius bg-runtime pb-runtime-b pl-runtime-l pr-runtime-r pt-runtime-t',
        !hero && 'absolute bottom-0 right-0'
      )}
    >
      <p className="font-runtime-font-family text-runtime-font-size leading-runtime-line-height text-runtime-color">
        {duration}
      </p>
    </div>
  );
}

export default Runtime;
