import { useLoaderData } from '@remix-run/react';

import { getPluginIdentifier } from '~/services/ui-components/index.ts';
import { Cell } from '~/components/cell/index.tsx';

import type { Feed, FeedEntry, UIComponent } from '~/services/layout/index.ts';
import type { loader as rootLoader } from '~/routes/_index.ts';
import { getPluginConfig } from '~/utils/get-plugin-configuration.ts';
import manifest from '../config/manifest.ts';

type Props = {
  uiComponent: UIComponent;
  feed?: Feed;
  isLoading?: boolean;
  isClient?: boolean;
};

/**
 * Render Group Info Component
 * <language>title_override is null when empty.
 * if empty there will be no title.
 * Api component_type: group-info-qb
 * @param uiComponent
 * @param feed
 */
export default function GroupInfo({
  uiComponent,
  feed,
  isLoading,
  isClient,
}: Props): JSX.Element {
  const { cellStyles } = useLoaderData<typeof rootLoader>();
  const config = getPluginConfig<typeof manifest>(manifest, uiComponent);

  const cellId = uiComponent.styles?.cell_plugin_configuration_id;
  const selectable = !!config?.rules?.component_cells_selectable;
  const pluginIdentifier = getPluginIdentifier(cellStyles, cellId);

  const entry = isLoading
    ? undefined
    : ({
        ...feed,
        title: config.localizations.title_override || feed?.title,
      } as FeedEntry);

  return (
    <section
      className={`component-${uiComponent.id} mb-component-b ml-component-l
      mr-component-r mt-component-t
      h-fit w-screen overflow-hidden border-none bg-component pb-component-b pl-component-l pr-component-r pt-component-t
      `}
    >
      <Cell
        selectable={selectable}
        isLoading={isLoading}
        pluginIdentifier={pluginIdentifier}
        entry={entry}
        cellId={cellId}
        isClient={isClient}
        uiComponent={uiComponent}
      />
    </section>
  );
}
