import { useLoaderData } from '@remix-run/react';
import type { loader as rootLoader } from '~/routes/_index.tsx';
import type { UIComponent } from '~/services/layout';
import { getClientFeedUrl } from '~/services/request';
import { isValidUrl } from '~/utils/validations';
import useCurrentLanguage from './use-current-language';
import { getComputedUrl } from '~/services/pipes/utils';

export function useClientFeedApiEndpoint({
  uiComponent,
}: {
  uiComponent: UIComponent;
}): string | null {
  const { serverLoadedFeeds, baseURL } = useLoaderData<typeof rootLoader>();

  const currentLanguage: string = useCurrentLanguage();

  const feedUrl = getComputedUrl(
    uiComponent,
    undefined,
    null,
    serverLoadedFeeds?.[0]?.feed?.entry?.[0]
  );

  if (!isValidUrl(feedUrl)) return null;

  const clientFeedApiEndpoint: string = addQueryParam({
    clientFeedApiEndpoint: `${baseURL}/${getClientFeedUrl(feedUrl!)}`,
    key: 'language',
    value: currentLanguage,
  });

  return clientFeedApiEndpoint || null;
}

function addQueryParam({
  clientFeedApiEndpoint,
  key,
  value,
}: {
  clientFeedApiEndpoint: string;
  key: string;
  value: string;
}): string {
  try {
    const clientFeedApiEndpointURL = new URL(clientFeedApiEndpoint);
    clientFeedApiEndpointURL.searchParams.set(key, value);
    return `${clientFeedApiEndpointURL.pathname}${clientFeedApiEndpointURL.search}`;
  } catch (error) {
    return clientFeedApiEndpoint;
  }
}
