import {
  getCellConfig,
  type CellPluginIdentifier,
} from '~/services/layout/get-cell-config';
import type { UIComponent } from '~/services/layout/index';
import { getCellPluginConfigurationIds } from '~/services/layout/layout';

/**
 * Get the cell plugin identifiers, styles, and assets for each breakpoint
 * @param uiComponent - the UIComponent object
 * @returns identifiers, styles, assets - each with keys mobile, tablet, desktop, and large-desktop
 */
export function getResponsiveCellConfigs({
  cellStyles,
  uiComponent,
}: {
  cellStyles: any;
  uiComponent: UIComponent;
}) {
  const { mobileCellId, tabletCellId, desktopCellId, largeDesktopCellId } =
    getCellPluginConfigurationIds(uiComponent);

  const mobileCellConfig: any = cellStyles[mobileCellId] || {};
  const tabletCellConfig: any = cellStyles[tabletCellId] || {};
  const desktopCellConfig: any = cellStyles[desktopCellId] || {};
  const largeDesktopCellConfig: any = cellStyles[largeDesktopCellId] || {};

  const identifiers: {
    mobile: CellPluginIdentifier;
    tablet: CellPluginIdentifier;
    desktop: CellPluginIdentifier;
    'large-desktop': CellPluginIdentifier;
  } = {
    mobile: mobileCellConfig?.plugin_identifier,
    tablet: tabletCellConfig?.plugin_identifier,
    desktop: desktopCellConfig?.plugin_identifier,
    'large-desktop': largeDesktopCellConfig?.plugin_identifier,
  };

  const { styles: mobileStyles, assets: mobileAssets } = getCellConfig(
    mobileCellConfig,
    identifiers.mobile
  );
  const { styles: tabletStyles, assets: tabletAssets } = getCellConfig(
    tabletCellConfig,
    identifiers.tablet
  );
  const { styles: desktopStyles, assets: desktopAssets } = getCellConfig(
    desktopCellConfig,
    identifiers.desktop
  );
  const { styles: largeDesktopStyles, assets: largeDesktopAssets } =
    getCellConfig(largeDesktopCellConfig, identifiers['large-desktop']);

  const styles = {
    mobile: mobileStyles,
    tablet: tabletStyles,
    desktop: desktopStyles,
    'large-desktop': largeDesktopStyles,
  };

  const assets = {
    mobile: mobileAssets,
    tablet: tabletAssets,
    desktop: desktopAssets,
    'large-desktop': largeDesktopAssets,
  };

  return {
    identifiers,
    styles,
    assets,
  };
}
