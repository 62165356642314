import type { Feed, FeedEntry } from '~/services/layout/index.ts';
import {
  getCellsScrolled,
  getClientWidth,
  getToggleButtonsState,
  getWidth,
  getHorizontalListButtonPosition,
  getIsLastCell,
} from './getters.ts';
import { useEffect, useRef, useState } from 'react';
import { useReadyState } from '~/hooks/use-ready-state.ts';
import { triggerScrollAction } from './trigger-scroll-action.ts';
import debounce from 'lodash.debounce';
import { useNavigation } from '@remix-run/react';

export function useHorizontalListResize({
  entries,
  ref,
  cellRef,
  nextFeedUrl,
  circularCondition,
  setToggleButtons,
  isLoading,
  gutter,
}: {
  entries: FeedEntry[] | undefined;
  ref: any;
  cellRef: any;
  nextFeedUrl: string | undefined;
  circularCondition: boolean;
  setToggleButtons: any;
  isLoading: boolean | undefined;
  gutter: number | undefined;
}) {
  const readyState = useReadyState();

  const handleResize = (): void => {
    const cellWidth: number = getWidth({
      ref: cellRef,
      gutter,
    });

    if (cellWidth === 0) return;

    const enabled: boolean = isEnabledOnResize({
      entries,
      ref,
      cellRef,
      nextFeedUrl,
      isCircularStarted: circularCondition,
      gutter: 0,
    });

    const { left, right } = getToggleButtonsState({
      ref,
      circularCondition,
      nextFeedUrl,
    });

    setToggleButtons({
      enabled,
      left,
      right,
      now: new Date().getTime(),
    });
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    handleResize();
  }, [readyState, isLoading]);
}

function isEnabledOnResize({
  entries,
  ref,
  cellRef,
  nextFeedUrl,
  isCircularStarted,
  gutter,
}: {
  entries: FeedEntry[] | undefined;
  ref: any;
  cellRef: any;
  nextFeedUrl: string | undefined;
  isCircularStarted: boolean;
  gutter: number | undefined;
}): boolean {
  const containerWidth: number = getClientWidth({ ref });

  const cellWidth: number = getWidth({
    ref: cellRef,
    gutter,
  });

  if (!gutter) gutter = 0;

  return (
    !!(
      containerWidth &&
      containerWidth < (cellWidth + gutter) * entries!.length - gutter
    ) ||
    isCircularStarted ||
    !!nextFeedUrl
  );
}

/**
 * Dispach resize event when the right button is enabled
 * @param ref - The horizontal list container ref, this is the most parent container
 * @param isCircularStarted - The circular state
 * @param nextFeedUrl - The next feed url
 */
export function useDispachResizeEvent({
  ref,
  circularCondition,
  nextFeedUrl,
}: {
  ref: any;
  circularCondition: boolean;
  nextFeedUrl: string | undefined;
}) {
  const { right } = getToggleButtonsState({
    ref,
    circularCondition,
    nextFeedUrl,
  });

  useEffect(() => {
    if (!right) return;

    setTimeout(() => {
      const event = new Event('resize');
      window.dispatchEvent(event);
    }, 0);
  }, [right]);
}

export type HorizontalListButtonPosition = {
  maxHeight: string;
  isMaxHeightReady: boolean;
};

export function useHorizontalListButtonPosition({
  isNextFeedLoading,
  arrow_position,
  ref,
  cellRef,
  position,
}: {
  isNextFeedLoading: boolean;
  arrow_position: string;
  ref: any;
  cellRef: any;
  position: string;
}): HorizontalListButtonPosition {
  const realTimeButtonPosition = getHorizontalListButtonPosition({
    position: arrow_position,
    ref,
    cellRef,
  });

  const isInvalidRealTimeButtonPosition: boolean =
    realTimeButtonPosition === 'auto' || realTimeButtonPosition === '0px';

  const uninterruptibleButtonPosition: React.MutableRefObject<string> =
    useRef<string>(realTimeButtonPosition);

  useEffect(() => {
    if (isNextFeedLoading || isInvalidRealTimeButtonPosition) return;
    uninterruptibleButtonPosition.current = realTimeButtonPosition;
  }, [isNextFeedLoading, realTimeButtonPosition]);

  if (position !== 'image_center')
    return {
      maxHeight: realTimeButtonPosition,
      isMaxHeightReady: realTimeButtonPosition !== 'auto',
    };

  const maxHeight = isInvalidRealTimeButtonPosition
    ? uninterruptibleButtonPosition.current
    : realTimeButtonPosition;

  const horizontalListButtonPosition = {
    maxHeight,
    isMaxHeightReady: maxHeight !== 'auto' && maxHeight !== '0px',
  };

  return horizontalListButtonPosition;
}

/**
 * Autoscroll feature for the horizontal list
 * @param ref - The horizontal list container ref, this is the most parent container
 * with a ref attached to it
 * @param cellRef - The cell ref
 * @param gutter - The component horizontal gutter
 * @param feed - The feed object
 * @param isLoading - The loading state of client loaded feed
 * @param scrollRight - The scroll right function
 * @param scrollLeft - The scroll left function
 * @param feedEntries - The feed entries state
 * @param autoscrollSwitch - The autoscroll switch
 * @param autoscrollInterval - The autoscroll interval
 * @param loopSwitch - The loop switch of the autoscroll feature
 */
export function useHorizontalListAutoScroll({
  ref,
  cellRef,
  gutter,
  feed,
  isLoading,
  scrollRight,
  feedEntries,
  autoscrollSwitch,
  autoscrollInterval,
  loopSwitch,
}: {
  ref: any;
  cellRef: any;
  gutter: any;
  feed: Feed | undefined;
  isLoading: boolean | undefined;
  scrollRight: Function;
  feedEntries: FeedEntry[] | undefined;
  autoscrollSwitch: boolean;
  autoscrollInterval: number;
  loopSwitch: boolean;
}) {
  const isUserInteractingRef = useRef<boolean>(false);

  const stopHorizontalListAutoScroll: boolean = useStopHorizontalListAutoScroll(
    {
      autoscrollSwitch,
      ref,
      autoscrollInterval,
    }
  );

  useEffect(() => {
    isUserInteractingRef.current = stopHorizontalListAutoScroll;
  }, [stopHorizontalListAutoScroll]);

  useEffect(() => {
    const originalFeedEntriesLength: number = feed?.entry?.length || 0;

    if (!autoscrollSwitch || isLoading || originalFeedEntriesLength === 0)
      return;

    const interval = setInterval(() => {
      if (isUserInteractingRef.current) return;

      const isLastCell: boolean = getIsLastCell({
        ref,
        cellRef,
        feedEntriesLength: feedEntries?.length || 0,
        gutter,
      });

      if (!loopSwitch && isLastCell) return;

      triggerScrollAction({
        ref,
        cellRef,
        gutter,
        feedEntriesLength: feedEntries?.length || 0,
        circularCondition: loopSwitch,
        scrollRight,
        scrollLeft: () => {},
        direction: 'right',
      });
    }, autoscrollInterval * 1000);

    return () => clearInterval(interval);
  }, [isLoading, feedEntries?.length]);
}

/**
 * Duplicate the feed entries when circular condition is enabled
 * and the feed entries length is equal to the original feed entries length
 * and the loading state of client loaded feed is false
 * @param feed - The feed object
 * @param feedEntries - The feed entries state
 * @param setFeedEntries - The feed entries state setter
 * @param isLoading - The loading state of client loaded feed
 * @param circularCondition - The circular condition which triggers the duplication of feed entries
 * @param ref - The horizontal list container ref, this is the most parent container with a ref attached to it
 * @param cellRef - The cell ref
 * @param gutter - The component horizontal gutter
 * @param scrollLeft - The scroll left function
 * circularCondition is true when circular scoll is enabled OR autoscroll is enabled with loop
 */
export function useDuplicatedFeedEntry({
  feedEntries,
  setFeedEntries,
  isLoading,
  circularCondition,
  originalFeedEntriesLength,
  shouldDuplicateEntries,
}: {
  feedEntries: FeedEntry[] | undefined;
  setFeedEntries: Function;
  isLoading: boolean | undefined;
  circularCondition: boolean;
  originalFeedEntriesLength: number;
  shouldDuplicateEntries: boolean;
}): void {
  useEffect(() => {
    if (!circularCondition || isLoading || originalFeedEntriesLength === 0)
      return;

    if (!shouldDuplicateEntries) return;

    setFeedEntries((prevFeedEntries: FeedEntry[] | []) => {
      const duplicatedFeedEntries = prevFeedEntries.map((entry, i) => {
        const _key = `duplicated-entry-${i}`;
        return {
          ...entry,
          _key,
          id: _key,
        };
      });

      return [...prevFeedEntries, ...duplicatedFeedEntries];
    });
  }, [isLoading, feedEntries?.length, shouldDuplicateEntries]);
}

/**
 * Scroll the horizontal list so the last cell is visible when the circular condition is enabled
 * @param ref - The horizontal list container ref, this is the most parent container with a ref attached to it
 * @param cellRef - The cell ref
 * @param gutter - The component horizontal gutter
 * @param circularCondition - The circular condition which triggers the duplication of feed entries
 * @param scrollLeft - The scroll left function
 * @param shouldDuplicateEntries - The duplication condition
 * @param originalFeedEntriesLength - The original feed entries length
 * @param feedEntries - The feed entries state
 * @param isLoading - The loading state of client loaded feed
 * @param setIsEntriesLoading - The feed entries loading state setter
 * @param isEntriesLoading - The feed entries loading state
 * @returns - The loading state of circular scroll on initial load
 */
export function useHorizontalListCircularScrollInstantScrollOnInitialLoad({
  ref,
  cellRef,
  gutter,
  circularCondition,
  scrollLeft,
  shouldDuplicateEntries,
  originalFeedEntriesLength,
  feedEntries,
  isLoading,
  setIsEntriesLoading,
  isEntriesLoading,
}: {
  ref: any;
  cellRef: any;
  gutter: number | undefined;
  circularCondition: boolean;
  scrollLeft: Function;
  shouldDuplicateEntries: boolean;
  originalFeedEntriesLength: number;
  feedEntries: FeedEntry[] | undefined;
  isLoading: boolean | undefined;
  setIsEntriesLoading: Function;
  isEntriesLoading: boolean;
}): boolean {
  useEffect(() => {
    if (shouldDuplicateEntries || !circularCondition) return;

    triggerScrollAction({
      ref,
      cellRef,
      gutter,
      feedEntriesLength: originalFeedEntriesLength,
      circularCondition: true,
      scrollRight: () => {},
      scrollLeft,
      direction: 'left',
      isInitialLoad: true,
    });

    setIsEntriesLoading(false);
  }, [feedEntries?.length, isLoading]);

  return circularCondition && isEntriesLoading;
}

export function useUpdateIndicatorsIndexOnScroll({
  ref,
  cellRef,
  setCurrentIndicatorsIndex,
  indicators_switch,
  circularCondition,
  feedEntry,
  autoscrollSwitch,
  loopSwitch,
  isCircularEntriesLoading,
}: {
  ref: any;
  cellRef: any;
  setCurrentIndicatorsIndex: any;
  indicators_switch: boolean;
  circularCondition: boolean;
  feedEntry: FeedEntry[] | undefined;
  autoscrollSwitch: boolean;
  loopSwitch: boolean;
  isCircularEntriesLoading: boolean;
}) {
  const handleScroll = debounce(() => {
    if (!feedEntry) return;

    const currentLeftSideCellIndex: number = getCellsScrolled({
      ref,
      cellRef,
      gutter: 0,
    });

    const entriesLength: number = feedEntry.length - 1;

    setCurrentIndicatorsIndex(() => {
      if (circularCondition && currentLeftSideCellIndex > entriesLength) {
        return currentLeftSideCellIndex - entriesLength - 1;
      }
      return currentLeftSideCellIndex;
    });
  }, 200);

  useEffect(() => {
    if (!indicators_switch || isCircularEntriesLoading) return;

    handleScroll();

    ref.current.addEventListener('scroll', handleScroll);

    return () => {
      if (!ref.current) return;
      ref.current?.removeEventListener('scroll', handleScroll);
    };
  }, [autoscrollSwitch, loopSwitch, isCircularEntriesLoading]);
}

export function useHorizontalListScroll({
  ref,
  circularCondition,
  setToggleButtons,
  nextFeedUrl,
  isScrolling,
  setIsScrolling,
}: {
  ref: any;
  circularCondition: boolean;
  setToggleButtons: any;
  nextFeedUrl: string | undefined;
  isScrolling: boolean;
  setIsScrolling: Function;
}): void {
  let isScrollingTimeout: NodeJS.Timeout;

  const handleScroll = () => {
    const { right, left } = getToggleButtonsState({
      ref,
      circularCondition,
      nextFeedUrl,
    });

    setToggleButtons((prev: any) => {
      return {
        ...prev,
        left,
        right,
      };
    });

    if (!isScrolling) setIsScrolling(true);

    clearTimeout(isScrollingTimeout);

    isScrollingTimeout = setTimeout(() => {
      setIsScrolling(false);
    }, 200);
  };

  useEffect(() => {
    handleScroll();

    ref.current.addEventListener('scroll', handleScroll);

    return () => {
      if (!ref.current) return;
      ref.current?.removeEventListener('scroll', handleScroll);
    };
  }, []);
}

/**
 * Stop the autoscroll when the user interacts with the horizontal list
 * @example auto scroll will stop for 10 seconds (the interval * 2) if the user:
 * - Click to favorite / unfavorite.
 * - Click the right / left arrows.
 * @example auto scroll will stop w/ out continuation if the user:
 * - Click the cell link.
 * - Click a buttons container button / play / trailer.
 * @param autoscrollSwitch - The autoscroll switch
 * @param ref - The horizontal list container ref, this is the most parent container
 * @param autoscrollInterval - The autoscroll interval
 * @returns
 */
function useStopHorizontalListAutoScroll({
  autoscrollSwitch,
  ref,
  autoscrollInterval,
}: {
  autoscrollSwitch: boolean;
  ref: any;
  autoscrollInterval: number;
}): boolean {
  const [isUserInteracting, setIsUserInteracting] = useState<boolean>(false);

  const navigation = useNavigation();

  const handleClick = () => {
    setIsUserInteracting(true);

    const interval: number =
      (isNaN(autoscrollInterval) ? 5 : autoscrollInterval) * 2;

    setTimeout(() => {
      if (navigation.state === 'loading') return;
      setIsUserInteracting(false);
    }, interval * 1000);
  };

  useEffect(() => {
    if (!autoscrollSwitch) return;

    const element = ref?.current;

    if (!element) return;

    element.addEventListener('click', handleClick);

    return () => {
      element.removeEventListener('click', handleClick);
    };
  }, []);

  return isUserInteracting;
}

export function useHorizontalListSnap({
  ref,
  isScrolling,
}: {
  ref: any;
  isScrolling: boolean;
}): void {
  useEffect(() => {
    if (!ref.current) return;

    if (isScrolling) {
      ref.current.style.scrollSnapType = 'none';
      return;
    }

    ref.current.style.scrollSnapType = 'x mandatory';
  }, [isScrolling]);
}
