import React, { useEffect, useRef, useState } from 'react';
import { useLoaderData } from '@remix-run/react';

import logger from '~/services/logger';
import { Cell } from './cell/index.tsx';
import { getPluginIdentifier } from '~/services/ui-components/index.ts';
import {
  getUniqueKeyByEntry,
  getDynamicFeedEntries,
  getLimit,
} from '~/services/layout/layout.ts';

import type { Feed, FeedEntry, UIComponent } from '~/services/layout/index.ts';
import type { loader as rootLoader } from '~/routes/_index.ts';
import { Cell1Skeleton } from './cell-components/skeletons.tsx';
import {
  type UseResponsiveBreakpoint,
  useResponsiveBreakpoint,
} from '~/hooks/use-responsive-breakpoint.ts';
import { useNextFeedPagination } from '~/hooks/use-next-feed.ts';
import { useFeedEntriesV1 } from '~/hooks/use-feed-entries.ts';
import { useFeedEntriesOnSearchQueryChange } from '~/hooks/use-feed-entries-on-search.ts';
import { useLastComponentInScreenPaginationCondition } from '~/hooks/use-pagination-condition.ts';

type Props = {
  uiComponent: UIComponent;
  feed?: Feed;
  isLoading?: boolean;
  isClient?: boolean;
};

export default function Grid({
  uiComponent,
  feed,
  isLoading,
  isClient,
}: Props): JSX.Element {
  const cellContainerRefs = useRef<any>([]);

  const { cellStyles } = useLoaderData<typeof rootLoader>();

  const [feedEntries, setFeedEntries] = useState<FeedEntry[]>([]);

  const [nextFeedUrl, setNextFeedUrl] = useState<string>(feed?.next || '');
  const [isNextFeedLoading, setIsNextFeedLoading] = useState<boolean>(false);

  const loadingSkeletons: {}[] = useGridLoadingSkeletons({
    isLoading,
  });

  const feedPath: string | undefined = feed?._feedPath;

  const selectable: boolean | undefined =
    uiComponent?.rules?.component_cells_selectable;

  const limit: number | undefined = getLimit(uiComponent.rules?.item_limit);

  const initialFeedEntries: FeedEntry[] | [] =
    feed?.entry?.slice(0, limit) || [];

  const entries: FeedEntry[] | [] = isLoading
    ? new Array(10).fill({})
    : initialFeedEntries;

  const dynamicFeedEntries: FeedEntry[] | [] = getDynamicFeedEntries({
    entries,
    feedEntries,
    isClient: !!isClient,
  });

  useFeedEntriesOnSearchQueryChange({
    setFeedEntries,
    entries,
  });

  const cellId: string | undefined =
    uiComponent?.styles?.cell_plugin_configuration_id;

  const pluginIdentifier: string | undefined = getPluginIdentifier(
    cellStyles,
    cellId
  );

  useFeedEntriesV1({
    initialFeedEntries,
    setFeedEntries,
  });

  const paginationCondition: boolean =
    useLastComponentInScreenPaginationCondition({
      refs: cellContainerRefs,
      isLoading,
      uiComponentId: uiComponent?.id,
    });

  useNextFeedPagination({
    feed,
    nextFeedUrl,
    setNextFeedUrl,
    setIsNextFeedLoading,
    isNextFeedLoading,
    setFeedEntries,
    feedEntries,
    itemLimit: uiComponent.rules?.item_limit,
    condition: paginationCondition,
    isLoading,
    isLoadingSkeletonsV2: false,
  });

  try {
    return (
      <>
        <div className="flex justify-evenly">
          <section
            className={`
          component-${uiComponent.id}
          mb-component-b
          ml-component-l
          mr-component-r mt-component-t
          grid h-fit w-full shrink-0 grid-cols-grid-mobile gap-component
          overflow-x-auto overflow-y-hidden border-none bg-component pb-component-b pl-component-l pr-component-r pt-component-t
          scrollbar-hide md:grid-cols-grid-tablet lg:grid-cols-grid-desktop
          `}
            data-testid={`grid-container-${uiComponent.id}`}
          >
            {React.Children.toArray(
              dynamicFeedEntries?.map((entry, index) => {
                const key: string = getUniqueKeyByEntry('grid', entry, index);
                return (
                  <div
                    ref={(el) => cellContainerRefs.current.push(el)}
                    key={key}
                    id={key}
                  >
                    <Cell
                      selectable={selectable}
                      pluginIdentifier={pluginIdentifier}
                      entry={entry}
                      cellId={cellId}
                      feedPath={feedPath}
                      isLoading={isLoading}
                      isClient={isClient}
                      uiComponent={uiComponent}
                    />
                  </div>
                );
              })
            )}
            {isNextFeedLoading &&
              React.Children.toArray(
                loadingSkeletons?.map((_) => {
                  return <Cell1Skeleton cellId={cellId} />;
                })
              )}
          </section>
        </div>
      </>
    );
  } catch (error: any) {
    logger.info(`Grid: ${error.message}`);
    return <></>;
  }
}

function useGridLoadingSkeletons({
  isLoading,
}: {
  isLoading: boolean | undefined;
}): {}[] {
  const [loadingSkeletons, setLoadingSkeletons] = useState<{}[]>(
    new Array(6).fill({})
  );

  const responsiveBreakpoint: UseResponsiveBreakpoint =
    useResponsiveBreakpoint();

  useEffect(() => {
    setLoadingSkeletons(
      new Array(
        responsiveBreakpoint === 'mobile'
          ? 2
          : responsiveBreakpoint === 'tablet'
          ? 4
          : 6
      ).fill({})
    );
  }, [responsiveBreakpoint, isLoading]);

  return loadingSkeletons;
}
