const manifest = {
  api: {},
  plugin_id: 3610,
  manifest_version: '1.0.5',
  dependency_repository_url: [],
  dependency_name: '',
  author_name: 'Applicaster',
  author_email: 'zapp@applicaster.com',
  name: 'Grid V2',
  description:
    'You’ll use the `grid` component in a large variety of screens, listing items of any type. All cells are displayed vertically on top of one another.',
  type: 0,
  screen: false,
  react_native: true,
  identifier: 'grid_v2',
  ui_builder_support: true,
  whitelisted_account_ids: [],
  deprecated_since_zapp_sdk: '',
  unsupported_since_zapp_sdk: '',
  targets: ['web'],
  ui_frameworks: ['quickbrick'],
  platform: 'web',
  dependency_version: '1.0.0',
  min_zapp_sdk: '0.0.1',
  styles: {
    fields: [
      {
        group: true,
        label: 'Cell Styles',
        folded: false,
        fields: [
          {
            key: 'mobile_cell_plugin_configuration_id',
            label: 'Mobile cell style',
            type: 'cell_style_selector',
          },
          {
            key: 'tablet_cell_plugin_configuration_id',
            label: 'Tablet cell style',
            type: 'cell_style_selector',
          },
          {
            key: 'desktop_cell_plugin_configuration_id',
            label: 'Desktop cell style',
            type: 'cell_style_selector',
          },
          {
            key: 'large_desktop_cell_plugin_configuration_id',
            label: 'Large Desktop cell style',
            type: 'cell_style_selector',
          },
        ],
      },
      {
        group: true,
        label: 'Component configuration',
        folded: false,
        fields: [
          {
            key: 'component_mobile_background_color',
            label: 'Component Mobile Background Color',
            initial_value: 'transparent',
            type: 'color_picker_rgba',
          },
          {
            key: 'component_tablet_background_color',
            label: 'Component Tablet Background Color',
            initial_value: 'transparent',
            type: 'color_picker_rgba',
          },
          {
            key: 'component_desktop_background_color',
            label: 'Component Desktop Background Color',
            initial_value: 'transparent',
            type: 'color_picker_rgba',
          },
          {
            key: 'component_large_desktop_background_color',
            label: 'Component Large Desktop Background Color',
            initial_value: 'transparent',
            type: 'color_picker_rgba',
          },
          {
            key: 'component_mobile_margin_top',
            label: 'Component Mobile Margin Top',
            initial_value: null,
            type: 'number_input',
          },
          {
            key: 'component_tablet_margin_top',
            label: 'Component Tablet Margin Top',
            initial_value: null,
            type: 'number_input',
          },
          {
            key: 'component_desktop_margin_top',
            label: 'Component Desktop Margin Top',
            initial_value: null,
            type: 'number_input',
          },
          {
            key: 'component_large_desktop_margin_top',
            label: 'Component Large Desktop Margin Top',
            initial_value: null,
            type: 'number_input',
          },

          {
            key: 'component_mobile_margin_bottom',
            label: 'Component Mobile Margin Bottom',
            initial_value: null,
            type: 'number_input',
          },
          {
            key: 'component_tablet_margin_bottom',
            label: 'Component Tablet Margin Bottom',
            initial_value: null,
            type: 'number_input',
          },
          {
            key: 'component_desktop_margin_bottom',
            label: 'Component Desktop Margin Bottom',
            initial_value: null,
            type: 'number_input',
          },
          {
            key: 'component_large_desktop_margin_bottom',
            label: 'Component Large Desktop Margin Bottom',
            initial_value: null,
            type: 'number_input',
          },
          {
            key: 'component_mobile_padding_top',
            label: 'Component Mobile Padding Top',
            initial_value: null,
            type: 'number_input',
          },
          {
            key: 'component_tablet_padding_top',
            label: 'Component Tablet Padding Top',
            initial_value: null,
            type: 'number_input',
          },
          {
            key: 'component_desktop_padding_top',
            label: 'Component Desktop Padding Top',
            initial_value: null,
            type: 'number_input',
          },
          {
            key: 'component_large_desktop_padding_top',
            label: 'Component Large Desktop Padding Top',
            initial_value: null,
            type: 'number_input',
          },
          {
            key: 'component_mobile_padding_right',
            label: 'Component Mobile Padding Right',
            initial_value: null,
            type: 'number_input',
          },
          {
            key: 'component_tablet_padding_right',
            label: 'Component Tablet Padding Right',
            initial_value: null,
            type: 'number_input',
          },
          {
            key: 'component_desktop_padding_right',
            label: 'Component Desktop Padding Right',
            initial_value: null,
            type: 'number_input',
          },
          {
            key: 'component_large_desktop_padding_right',
            label: 'Component Large Desktop Padding Right',
            initial_value: null,
            type: 'number_input',
          },
          {
            key: 'component_mobile_padding_bottom',
            label: 'Component Mobile Padding Bottom',
            initial_value: null,
            type: 'number_input',
          },
          {
            key: 'component_tablet_padding_bottom',
            label: 'Component Tablet Padding Bottom',
            initial_value: null,
            type: 'number_input',
          },
          {
            key: 'component_desktop_padding_bottom',
            label: 'Component Desktop Padding Bottom',
            initial_value: null,
            type: 'number_input',
          },
          {
            key: 'component_large_desktop_padding_bottom',
            label: 'Component Large Desktop Padding Bottom',
            initial_value: null,
            type: 'number_input',
          },

          {
            key: 'component_mobile_padding_left',
            label: 'Component Mobile Padding Left',
            initial_value: null,
            type: 'number_input',
          },
          {
            key: 'component_tablet_padding_left',
            label: 'Component Tablet Padding Left',
            initial_value: null,
            type: 'number_input',
          },
          {
            key: 'component_desktop_padding_left',
            label: 'Component Desktop Padding Left',
            initial_value: null,
            type: 'number_input',
          },
          {
            key: 'component_large_desktop_padding_left',
            label: 'Component Large Desktop Padding Left',
            initial_value: null,
            type: 'number_input',
          },

          {
            key: 'component_mobile_gutter_vertical',
            label: 'Component Mobile Gutter Vertical',
            initial_value: null,
            type: 'number_input',
          },
          {
            key: 'component_tablet_gutter_vertical',
            label: 'Component Tablet Gutter Vertical',
            initial_value: null,
            type: 'number_input',
          },
          {
            key: 'component_desktop_gutter_vertical',
            label: 'Component Desktop Gutter Vertical',
            initial_value: null,
            type: 'number_input',
          },
          {
            key: 'component_large_desktop_gutter_vertical',
            label: 'Component Large Desktop Gutter Vertical',
            initial_value: null,
            type: 'number_input',
          },
          {
            key: 'component_mobile_gutter_horizontal',
            label: 'Component Mobile Gutter Horizontal',
            initial_value: null,
            type: 'number_input',
          },
          {
            key: 'component_tablet_gutter_horizontal',
            label: 'Component Tablet Gutter Horizontal',
            initial_value: null,
            type: 'number_input',
          },
          {
            key: 'component_desktop_gutter_horizontal',
            label: 'Component Desktop Gutter Horizontal',
            initial_value: null,
            type: 'number_input',
          },
          {
            key: 'component_large_desktop_gutter_horizontal',
            label: 'Component Large Desktop Gutter Horizontal',
            initial_value: null,
            type: 'number_input',
          },
          {
            key: 'component_last_row_horizontal_alignment',
            label: 'Component Horizontal Alignment',
            label_tooltip:
              'Horizontal alignment for the cells in the last row of the grid',
            options: [
              {
                text: 'Start',
                value: 'start',
              },
              {
                text: 'Center',
                value: 'center',
              },
              {
                text: 'End',
                value: 'end',
              },
            ],
            initial_value: 'start',
            type: 'select',
          },
          {
            key: 'component_mobile_columns',
            label: 'Number Of Columns Mobile',
            initial_value: 2,
            label_tooltip: 'Number of columns on mobile',
            type: 'number_input',
          },
          {
            key: 'component_tablet_columns',
            label: 'Number Of Columns Tablet',
            initial_value: 3,
            label_tooltip: 'Number of columns on tablet',
            type: 'number_input',
          },
          {
            key: 'component_desktop_columns',
            label: 'Number Of Columns Desktop',
            initial_value: 4,
            label_tooltip: 'Number of columns on desktop',
            type: 'number_input',
          },
          {
            key: 'component_large_desktop_columns',
            label: 'Number Of Columns Large Desktop',
            initial_value: 5,
            label_tooltip: 'Number of columns on large desktop',
            type: 'number_input',
          },
        ],
      },
    ],
  },
  rules: {
    fields: [
      { key: 'item_limit', label: 'Item limit', type: 'number_input' },
      {
        key: 'component_cells_selectable',
        label: 'Cells selectable',
        initial_value: true,
        type: 'switch',
      },
      {
        key: 'load_feed_from_client',
        label: 'Load feed from the client',
        initial_value: false,
        type: 'switch',
      },
    ],
  },
  data: {
    fields: [
      { key: 'source', label: 'Source', type: 'text_input' },
      { key: 'type', label: 'Type', type: 'select' },
    ],
  },
  characteristics: {
    group: false,
    appearance: ['group', 'screen'],
    disable_default_cell_style: true,
  },
} as const;

export default manifest;
