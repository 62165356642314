import React from 'react';
import { useLoaderData } from '@remix-run/react';

import { getPluginIdentifier } from '~/services/ui-components/index.ts';
import { Cell } from './cell/index.tsx';
import { getLimit } from '~/services/layout/layout.ts';

import type { Feed, UIComponent } from '~/services/layout/index.ts';
import type { loader as rootLoader } from '~/routes/_index.ts';

type Props = {
  uiComponent: UIComponent;
  feed?: Feed;
};

export default function List({ uiComponent, feed }: Props): JSX.Element {
  const { cellStyles } = useLoaderData<typeof rootLoader>();

  try {
    const cellId = uiComponent?.styles?.cell_plugin_configuration_id;
    const limit = getLimit(uiComponent.rules?.item_limit);
    const pluginIdentifier = getPluginIdentifier(cellStyles, cellId);
    const feedPath = feed?._feedPath;
    const selectable = uiComponent?.rules?.component_cells_selectable;

    return (
      <>
        <section
          className={`
        component-${uiComponent.id}
        mb-component-b
        ml-component-l
        mr-component-r
        mt-component-t flex
        h-fit w-screen
        shrink-0 flex-col gap-component overflow-x-auto overflow-y-hidden border-none bg-component pb-component-b pl-component-l pr-component-r pt-component-t scrollbar-hide`}
        >
          {React.Children.toArray(
            feed?.entry?.slice(0, limit).map((entry) => {
              return (
                <Cell
                  cellId={cellId}
                  entry={entry}
                  pluginIdentifier={pluginIdentifier}
                  feedPath={feedPath}
                  selectable={selectable}
                  uiComponent={uiComponent}
                />
              );
            })
          )}
        </section>
      </>
    );
  } catch (error: any) {
    return <></>;
  }
}
