import type { loader as rootLoader } from '~/routes/_index.ts';
import { useLoaderData, useSearchParams } from '@remix-run/react';
import { FeedEntry } from '~/services/layout/index';
import { useEffect, useRef } from 'react';

/**
 * Set feed entries on search query change.
 * @param setFeedEntries - Function to set feed entries.
 * @param entries - Feed entries.
 */
export function useFeedEntriesOnSearchQueryChange({
  setFeedEntries,
  entries,
}: {
  setFeedEntries: (entries: FeedEntry[]) => void;
  entries: FeedEntry[];
}): void {
  const [searchParams] = useSearchParams();
  const { isSearchScreen } = useLoaderData<typeof rootLoader>();
  const q = searchParams.get('q');
  const prevQ: {
    current: string | null;
  } = useRef(null);

  useEffect(() => {
    if (!isSearchScreen) return;
    if (q !== prevQ.current) setFeedEntries(entries);
    prevQ.current = q;
  }, [q]);
}
