const manifest = {
  plugin_id: 3615,
  identifier: 'horizontal_list_v2',
  manifest_version: '0.0.1-alpha-16',
  dependency_name: '',
  name: 'Horizontal List',
  description: 'Horizontal List',
  type: 'ui_component',
  characteristics: {
    disable_default_cell_style: true,
  },
  rules: {
    fields: [
      {
        key: 'item_limit',
        label: 'Item limit',
        type: 'number_input',
      },
      {
        key: 'cells_selectable_switch',
        label: 'Cells selectable',
        type: 'switch',
        initial_value: true,
      },
      {
        key: 'enable_data_refreshing_switch',
        label: 'Enable data refreshing',
        type: 'switch',
        initial_value: false,
      },
      {
        key: 'refreshing_interval',
        label: 'Refreshing interval (sec)',
        type: 'number_input',
        initial_value: 60,
        conditional_fields: [
          {
            key: 'rules/enable_data_refreshing_switch',
            condition_value: true,
          },
        ],
      },
      {
        key: 'load_feed_from_client_switch',
        label: 'Load feed from the client',
        type: 'switch',
        initial_value: false,
      },
      {
        key: 'circular_scroll_switch',
        label: 'Enable circular scrolling',
        label_tooltip:
          'Once the user reaches the end of the list, the list will show the first items and vice versa. If pagination is enabled, the list will become circular when pagination is done.',
        type: 'switch',
        initial_value: false,
      },
      {
        group: true,
        label: 'Advanced configuration',
        folded: true,
        fields: [
          {
            key: 'clear_cache_on_relead',
            label: 'Clear cache on relead',
            type: 'switch',
            initial_value: false,
          },
        ],
      },
      // {
      //   group: true,
      //   label: 'Video Preview',
      //   folded: true,
      //   fields: [
      //     {
      //       key: 'enable_video_preview_switch',
      //       label: 'Enable video preview',
      //       type: 'switch',
      //       initial_value: false,
      //     },
      //     {
      //       key: 'video_screen',
      //       label: 'Video screen',
      //       type: 'screen_selector',
      //       initial_value: null,
      //       conditional_fields: [
      //         {
      //           key: 'rules/enable_video_preview_switch',
      //           condition_value: true,
      //         },
      //       ],
      //     },
      //   ],
      // },
      {
        group: true,
        label: 'Mobile Autoscroll & Loop',
        folded: true,
        fields: [
          {
            key: 'autoscroll_switch_mobile',
            label: 'Autoscroll',
            type: 'switch',
            initial_value: false,
          },
          {
            key: 'autoscroll_interval_mobile',
            label: 'Autoscroll interval',
            type: 'number_input',
            initial_value: 5,
            conditional_fields: [
              {
                key: 'rules/autoscroll_switch_mobile',
                condition_value: true,
              },
            ],
          },
          {
            key: 'loop_switch_mobile',
            label: 'Loop',
            type: 'switch',
            initial_value: false,
            conditional_fields: [
              {
                key: 'rules/autoscroll_switch_mobile',
                condition_value: true,
              },
            ],
          },
        ],
      },
      {
        group: true,
        label: 'Tablet Autoscroll & Loop',
        folded: true,
        fields: [
          {
            key: 'autoscroll_switch_tablet',
            label: 'Autoscroll',
            type: 'switch',
            initial_value: false,
          },
          {
            key: 'autoscroll_interval_tablet',
            label: 'Autoscroll interval',
            type: 'number_input',
            initial_value: 5,
            conditional_fields: [
              {
                key: 'rules/autoscroll_switch_tablet',
                condition_value: true,
              },
            ],
          },
          {
            key: 'loop_switch_tablet',
            label: 'Loop',
            type: 'switch',
            initial_value: false,
            conditional_fields: [
              {
                key: 'rules/autoscroll_switch_tablet',
                condition_value: true,
              },
            ],
          },
        ],
      },
      {
        group: true,
        label: 'Desktop Autoscroll & Loop',
        folded: true,
        fields: [
          {
            key: 'autoscroll_switch_desktop',
            label: 'Autoscroll',
            type: 'switch',
            initial_value: false,
          },
          {
            key: 'autoscroll_interval_desktop',
            label: 'Autoscroll interval',
            type: 'number_input',
            initial_value: 5,
            conditional_fields: [
              {
                key: 'rules/autoscroll_switch_desktop',
                condition_value: true,
              },
            ],
          },
          {
            key: 'loop_switch_desktop',
            label: 'Loop',
            type: 'switch',
            initial_value: false,
            conditional_fields: [
              {
                key: 'rules/autoscroll_switch_desktop',
                condition_value: true,
              },
            ],
          },
        ],
      },
      {
        group: true,
        label: 'Large Desktop Autoscroll & Loop',
        folded: true,
        fields: [
          {
            key: 'autoscroll_switch_large_desktop',
            label: 'Autoscroll',
            type: 'switch',
            initial_value: false,
          },
          {
            key: 'autoscroll_interval_large_desktop',
            label: 'Autoscroll interval',
            type: 'number_input',
            initial_value: 5,
            conditional_fields: [
              {
                key: 'rules/autoscroll_switch_large_desktop',
                condition_value: true,
              },
            ],
          },
          {
            key: 'loop_switch_large_desktop',
            label: 'Loop',
            type: 'switch',
            initial_value: false,
            conditional_fields: [
              {
                key: 'rules/autoscroll_switch_large_desktop',
                condition_value: true,
              },
            ],
          },
        ],
      },
    ],
  },
  data: {
    fields: [{ key: 'source', label: 'Source', type: 'text_input' }],
  },
  styles: {
    fields: [
      {
        group: true,
        label: 'Cell Styles',
        folded: false,
        fields: [
          {
            key: 'mobile_cell_plugin_configuration_id',
            label: 'Mobile cell style',
            type: 'cell_style_selector',
          },
          {
            key: 'tablet_cell_plugin_configuration_id',
            label: 'Tablet cell style',
            type: 'cell_style_selector',
          },
          {
            key: 'desktop_cell_plugin_configuration_id',
            label: 'Desktop cell style',
            type: 'cell_style_selector',
          },
          {
            key: 'large_desktop_cell_plugin_configuration_id',
            label: 'Large Desktop cell style',
            type: 'cell_style_selector',
          },
        ],
      },
      {
        group: true,
        label: 'Component',
        folded: false,
        fields: [
          {
            key: 'component_mobile_margin_top',
            label: 'Component Mobile Margin Top',
            type: 'number_input',
            initial_value: null,
          },
          {
            key: 'component_tablet_margin_top',
            label: 'Component Tablet Margin Top',
            type: 'number_input',
            initial_value: null,
          },
          {
            key: 'component_desktop_margin_top',
            label: 'Component Desktop Margin Top',
            type: 'number_input',
            initial_value: null,
          },
          {
            key: 'component_large_desktop_margin_top',
            label: 'Component Large Desktop Margin Top',
            type: 'number_input',
            initial_value: null,
          },
          {
            key: 'component_mobile_margin_bottom',
            label: 'Component Mobile Margin Bottom',
            type: 'number_input',
            initial_value: null,
          },
          {
            key: 'component_tablet_margin_bottom',
            label: 'Component Tablet Margin Bottom',
            type: 'number_input',
            initial_value: null,
          },
          {
            key: 'component_desktop_margin_bottom',
            label: 'Component Desktop Margin Bottom',
            type: 'number_input',
            initial_value: null,
          },
          {
            key: 'component_large_desktop_margin_bottom',
            label: 'Component Large Desktop Margin Bottom',
            type: 'number_input',
            initial_value: null,
          },
          {
            key: 'component_mobile_padding_top',
            label: 'Component Mobile Padding Top',
            type: 'number_input',
            initial_value: null,
          },
          {
            key: 'component_tablet_padding_top',
            label: 'Component Tablet Padding Top',
            type: 'number_input',
            initial_value: null,
          },
          {
            key: 'component_desktop_padding_top',
            label: 'Component Desktop Padding Top',
            type: 'number_input',
            initial_value: null,
          },
          {
            key: 'component_large_desktop_padding_top',
            label: 'Component Large Desktop Padding Top',
            type: 'number_input',
            initial_value: null,
          },
          {
            key: 'component_mobile_padding_right',
            label: 'Component Mobile Padding Right',
            type: 'number_input',
            initial_value: null,
          },
          {
            key: 'component_tablet_padding_right',
            label: 'Component Tablet Padding Right',
            type: 'number_input',
            initial_value: null,
          },
          {
            key: 'component_desktop_padding_right',
            label: 'Component Desktop Padding Right',
            type: 'number_input',
            initial_value: null,
          },
          {
            key: 'component_large_desktop_padding_right',
            label: 'Component Large Desktop Padding Right',
            type: 'number_input',
            initial_value: null,
          },
          {
            key: 'component_mobile_padding_bottom',
            label: 'Component Mobile Padding Bottom',
            type: 'number_input',
            initial_value: null,
          },
          {
            key: 'component_tablet_padding_bottom',
            label: 'Component Tablet Padding Bottom',
            type: 'number_input',
            initial_value: null,
          },
          {
            key: 'component_desktop_padding_bottom',
            label: 'Component Desktop Padding Bottom',
            type: 'number_input',
            initial_value: null,
          },
          {
            key: 'component_large_desktop_padding_bottom',
            label: 'Component Large Desktop Padding Bottom',
            type: 'number_input',
            initial_value: null,
          },
          {
            key: 'component_mobile_padding_left',
            label: 'Component Mobile Padding Left',
            type: 'number_input',
            initial_value: null,
          },
          {
            key: 'component_tablet_padding_left',
            label: 'Component Tablet Padding Left',
            type: 'number_input',
            initial_value: null,
          },
          {
            key: 'component_desktop_padding_left',
            label: 'Component Desktop Padding Left',
            type: 'number_input',
            initial_value: null,
          },
          {
            key: 'component_large_desktop_padding_left',
            label: 'Component Large Desktop Padding Left',
            type: 'number_input',
            initial_value: null,
          },
          {
            key: 'component_mobile_gutter_horizontal',
            label: 'Component Mobile Gutter Horizontal',
            type: 'number_input',
            initial_value: null,
          },
          {
            key: 'component_tablet_gutter_horizontal',
            label: 'Component Tablet Gutter Horizontal',
            type: 'number_input',
            initial_value: null,
          },
          {
            key: 'component_desktop_gutter_horizontal',
            label: 'Component Desktop Gutter Horizontal',
            type: 'number_input',
            initial_value: null,
          },
          {
            key: 'component_large_desktop_gutter_horizontal',
            label: 'Component Large Desktop Gutter Horizontal',
            type: 'number_input',
            initial_value: null,
          },
          {
            key: 'component_mobile_background_color',
            label: 'Component Mobile background color',
            type: 'color_picker_rgba',
            initial_value: 'rgba(0, 0, 0, 0)',
          },
          {
            key: 'component_tablet_background_color',
            label: 'Component Tablet background color',
            type: 'color_picker_rgba',
            initial_value: 'rgba(0, 0, 0, 0)',
          },
          {
            key: 'component_desktop_background_color',
            label: 'Component Desktop background color',
            type: 'color_picker_rgba',
            initial_value: 'rgba(0, 0, 0, 0)',
          },
          {
            key: 'component_large_desktop_background_color',
            label: 'Component Large Desktop background color',
            type: 'color_picker_rgba',
            initial_value: 'rgba(0, 0, 0, 0)',
          },
        ],
      },
      {
        group: true,
        label: 'Cell',
        folded: false,
        fields: [
          {
            key: 'mobile_cell_scaling_switch',
            label: 'Mobile Cell scaling',
            type: 'switch',
            initial_value: true,
          },
          {
            key: 'tablet_cell_scaling_switch',
            label: 'Tablet Cell scaling',
            type: 'switch',
            initial_value: true,
          },
          {
            key: 'desktop_cell_scaling_switch',
            label: 'Desktop Cell scaling',
            type: 'switch',
            initial_value: true,
          },
          {
            key: 'large_desktop_cell_scaling_switch',
            label: 'Large Desktop Cell scaling',
            type: 'switch',
            initial_value: true,
          },
          {
            key: 'mobile_cells_per_view',
            label: 'Mobile Cells per view',
            type: 'number_input',
            initial_value: 2,
            conditional_fields: [
              {
                key: 'styles/mobile_cell_scaling_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'tablet_cells_per_view',
            label: 'Tablet Cells per view',
            type: 'number_input',
            initial_value: 3,
            conditional_fields: [
              {
                key: 'styles/tablet_cell_scaling_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'desktop_cells_per_view',
            label: 'Desktop Cells per view',
            type: 'number_input',
            initial_value: 4,
            conditional_fields: [
              {
                key: 'styles/desktop_cell_scaling_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'large_desktop_cells_per_view',
            label: 'Large Desktop Cells per view',
            type: 'number_input',
            initial_value: 5,
            conditional_fields: [
              {
                key: 'styles/large_desktop_cell_scaling_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'next_cell_mobile_offset',
            label: 'Next cell Mobile offset',
            type: 'number_input',
            initial_value: 12,
            conditional_fields: [
              {
                key: 'styles/mobile_cell_scaling_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'next_cell_tablet_offset',
            label: 'Next cell Tablet offset',
            type: 'number_input',
            initial_value: 36,
            conditional_fields: [
              {
                key: 'styles/tablet_cell_scaling_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'next_cell_desktop_offset',
            label: 'Next cell Desktop offset',
            type: 'number_input',
            initial_value: 48,
            conditional_fields: [
              {
                key: 'styles/desktop_cell_scaling_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'next_cell_large_desktop_offset',
            label: 'Next cell Large Desktop offset',
            type: 'number_input',
            initial_value: 48,
            conditional_fields: [
              {
                key: 'styles/large_desktop_cell_scaling_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'cell_mobile_width',
            label: 'Cell Mobile width',
            type: 'number_input',
            initial_value: 242,
            conditional_fields: [
              {
                key: 'styles/mobile_cell_scaling_switch',
                condition_value: false,
              },
            ],
          },
          {
            key: 'cell_tablet_width',
            label: 'Cell Tablet width',
            type: 'number_input',
            initial_value: 272,
            conditional_fields: [
              {
                key: 'styles/tablet_cell_scaling_switch',
                condition_value: false,
              },
            ],
          },
          {
            key: 'cell_desktop_width',
            label: 'Cell Desktop width',
            type: 'number_input',
            initial_value: 288,
            conditional_fields: [
              {
                key: 'styles/desktop_cell_scaling_switch',
                condition_value: false,
              },
            ],
          },
          {
            key: 'cell_large_desktop_width',
            label: 'Cell Large Desktop width',
            type: 'number_input',
            initial_value: 300,
            conditional_fields: [
              {
                key: 'styles/large_desktop_cell_scaling_switch',
                condition_value: false,
              },
            ],
          },
        ],
      },
      {
        group: true,
        label: 'Arrow',
        folded: false,
        fields: [
          {
            key: 'arrows_mobile_switch',
            label: 'Enable arrows Mobile',
            type: 'switch',
            initial_value: false,
          },
          {
            key: 'arrows_tablet_switch',
            label: 'Enable arrows Tablet',
            type: 'switch',
            initial_value: true,
          },
          {
            key: 'arrows_desktop_switch',
            label: 'Enable arrows Desktop',
            type: 'switch',
            initial_value: true,
          },
          {
            key: 'arrows_large_desktop_switch',
            label: 'Enable arrows Large Desktop',
            type: 'switch',
            initial_value: true,
          },
          {
            key: 'arrow_position',
            label: 'Arrow position',
            type: 'select',
            options: [
              {
                text: 'Image center',
                value: 'image_center',
              },
              {
                text: 'Cell center',
                value: 'cell_center',
              },
            ],
            initial_value: 'image_center',
          },
          {
            key: 'arrow_width',
            label: 'Arrow width',
            type: 'number_input',
            initial_value: 32,
          },
          {
            key: 'arrow_height',
            label: 'Arrow height',
            type: 'number_input',
            initial_value: 32,
          },
          {
            key: 'arrow_background_color',
            label: 'Arrow background color',
            type: 'color_picker_rgba',
            initial_value: 'rgba(0, 0, 0, 0)',
          },
          {
            key: 'arrow_background_color_hover',
            label: 'Hover arrow background color',
            type: 'color_picker_rgba',
            initial_value: 'rgba(0, 0, 0, 0)',
          },
          {
            key: 'inactive_arrow_background_color',
            label: 'Inactive arrow background color',
            type: 'color_picker_rgba',
            initial_value: 'transparent',
          },
          {
            key: 'arrow_border_radius',
            label: 'Arrow border radius',
            type: 'number_input',
            initial_value: 0,
          },
          {
            key: 'left_arrow_asset',
            label: 'Left arrow asset',
            type: 'uploader',
            initial_value:
              'data:image/svg+xml,<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M19.8839 9.11612C20.372 9.60427 20.372 10.3957 19.8839 10.8839L14.7678 16L19.8839 21.1161C20.372 21.6043 20.372 22.3957 19.8839 22.8839C19.3957 23.372 18.6043 23.372 18.1161 22.8839L12.1161 16.8839C11.628 16.3957 11.628 15.6043 12.1161 15.1161L18.1161 9.11612C18.6043 8.62796 19.3957 8.62796 19.8839 9.11612Z" fill="%23EFEFEF"/><path d="M20.0607 11.0607C20.6464 10.4749 20.6464 9.52513 20.0607 8.93934C19.4749 8.35355 18.5251 8.35355 17.9393 8.93934L11.9393 14.9393C11.3536 15.5251 11.3536 16.4749 11.9393 17.0607L17.9393 23.0607C18.5251 23.6464 19.4749 23.6464 20.0607 23.0607C20.6464 22.4749 20.6464 21.5251 20.0607 20.9393L15.1213 16L20.0607 11.0607Z" stroke="black" stroke-opacity="0.25" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round"/></svg>',
          },
          {
            key: 'hover_left_arrow_asset',
            label: 'Hover left arrow asset',
            type: 'uploader',
            initial_value:
              'data:image/svg+xml,<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(%23clip0_462_4089)"><path fill-rule="evenodd" clip-rule="evenodd" d="M21.1784 6.82116C21.8293 7.47204 21.8293 8.52731 21.1784 9.17819L14.3569 15.9997L21.1784 22.8212C21.8293 23.472 21.8293 24.5273 21.1784 25.1782C20.5276 25.8291 19.4723 25.8291 18.8214 25.1782L10.8214 17.1782C10.1705 16.5273 10.1705 15.472 10.8214 14.8212L18.8214 6.82116C19.4723 6.17029 20.5276 6.17029 21.1784 6.82116Z" fill="%23EFEFEF"/><path d="M21.3552 9.35496C22.1037 8.60646 22.1037 7.39289 21.3552 6.64439C20.6067 5.89588 19.3931 5.89588 18.6446 6.64439L10.6446 14.6444C9.89613 15.3929 9.89613 16.6065 10.6446 17.355L18.6446 25.355C19.3931 26.1035 20.6067 26.1035 21.3552 25.355C22.1037 24.6065 22.1037 23.3929 21.3552 22.6444L14.7105 15.9997L21.3552 9.35496Z" stroke="black" stroke-opacity="0.25" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round"/></g><defs><clipPath id="clip0_462_4089"><rect width="32" height="32" fill="white"/></clipPath></defs></svg>',
          },
          {
            key: 'right_arrow_asset',
            label: 'Right arrow asset',
            type: 'uploader',
            initial_value:
              'data:image/svg+xml,<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M12.1161 22.8839C11.628 22.3957 11.628 21.6043 12.1161 21.1161L17.2322 16L12.1161 10.8839C11.628 10.3957 11.628 9.60427 12.1161 9.11612C12.6043 8.62796 13.3957 8.62796 13.8839 9.11612L19.8839 15.1161C20.372 15.6043 20.372 16.3957 19.8839 16.8839L13.8839 22.8839C13.3957 23.372 12.6043 23.372 12.1161 22.8839Z" fill="%23EFEFEF"/><path d="M11.9393 20.9393C11.3536 21.5251 11.3536 22.4749 11.9393 23.0607C12.5251 23.6464 13.4749 23.6464 14.0607 23.0607L20.0607 17.0607C20.6464 16.4749 20.6464 15.5251 20.0607 14.9393L14.0607 8.93934C13.4749 8.35355 12.5251 8.35355 11.9393 8.93934C11.3536 9.52513 11.3536 10.4749 11.9393 11.0607L16.8787 16L11.9393 20.9393Z" stroke="black" stroke-opacity="0.25" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round"/></svg>',
          },
          {
            key: 'hover_right_arrow_asset',
            label: 'Hover right arrow asset',
            type: 'uploader',
            initial_value:
              'data:image/svg+xml,<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(%23clip0_593_4356)"><path fill-rule="evenodd" clip-rule="evenodd" d="M10.8216 25.1788C10.1707 24.528 10.1707 23.4727 10.8216 22.8218L17.6431 16.0003L10.8216 9.17884C10.1707 8.52796 10.1707 7.47269 10.8216 6.82181C11.4724 6.17094 12.5277 6.17094 13.1786 6.82181L21.1786 14.8218C21.8295 15.4727 21.8295 16.528 21.1786 17.1788L13.1786 25.1788C12.5277 25.8297 11.4724 25.8297 10.8216 25.1788Z" fill="%23EFEFEF"/><path d="M10.6448 22.645C9.89629 23.3935 9.89629 24.6071 10.6448 25.3556C11.3933 26.1041 12.6069 26.1041 13.3554 25.3556L21.3554 17.3556C22.1039 16.6071 22.1039 15.3935 21.3554 14.645L13.3554 6.64504C12.6069 5.89653 11.3933 5.89653 10.6448 6.64504C9.89629 7.39354 9.89629 8.60711 10.6448 9.35561L17.2895 16.0003L10.6448 22.645Z" stroke="black" stroke-opacity="0.25" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round"/></g><defs><clipPath id="clip0_593_4356"><rect width="32" height="32" fill="white"/></clipPath></defs></svg>',
          },
          {
            key: 'inactive_left_arrow_asset',
            label: 'Inactive left arrow asset',
            type: 'uploader',
          },
          {
            key: 'inactive_right_arrow_asset',
            label: 'Inactive right arrow asset',
            type: 'uploader',
          },
          {
            key: 'left_arrow_mobile_margin_left',
            label: 'Left arrow Mobile margin left',
            type: 'number_input',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'styles/arrows_mobile_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'left_arrow_mobile_margin_top',
            label: 'Left arrow Mobile margin top',
            type: 'number_input',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'styles/arrows_mobile_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'left_arrow_mobile_margin_bottom',
            label: 'Left arrow Mobile margin bottom',
            type: 'number_input',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'styles/arrows_mobile_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'left_arrow_mobile_margin_right',
            label: 'Left arrow Mobile margin right',
            type: 'number_input',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'styles/arrows_mobile_switch',
                condition_value: true,
              },
            ],
          },
          // Right arrow mobile
          {
            key: 'right_arrow_mobile_margin_left',
            label: 'Right arrow Mobile margin left',
            type: 'number_input',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'styles/arrows_mobile_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'right_arrow_mobile_margin_top',
            label: 'Right arrow Mobile margin top',
            type: 'number_input',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'styles/arrows_mobile_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'right_arrow_mobile_margin_bottom',
            label: 'Right arrow Mobile margin bottom',
            type: 'number_input',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'styles/arrows_mobile_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'right_arrow_mobile_margin_right',
            label: 'Right arrow Mobile margin right',
            type: 'number_input',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'styles/arrows_mobile_switch',
                condition_value: true,
              },
            ],
          },
          // Left arrow tablet
          {
            key: 'left_arrow_tablet_margin_left',
            label: 'Left arrow Tablet margin left',
            type: 'number_input',
            initial_value: 4,
            conditional_fields: [
              {
                key: 'styles/arrows_tablet_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'left_arrow_tablet_margin_top',
            label: 'Left arrow Tablet margin top',
            type: 'number_input',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'styles/arrows_tablet_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'left_arrow_tablet_margin_bottom',
            label: 'Left arrow Tablet margin bottom',
            type: 'number_input',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'styles/arrows_tablet_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'left_arrow_tablet_margin_right',
            label: 'Left arrow Tablet margin right',
            type: 'number_input',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'styles/arrows_tablet_switch',
                condition_value: true,
              },
            ],
          },
          // Right arrow tablet
          {
            key: 'right_arrow_tablet_margin_left',
            label: 'Right arrow Tablet margin left',
            type: 'number_input',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'styles/arrows_tablet_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'right_arrow_tablet_margin_top',
            label: 'Right arrow Tablet margin top',
            type: 'number_input',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'styles/arrows_tablet_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'right_arrow_tablet_margin_bottom',
            label: 'Right arrow Tablet margin bottom',
            type: 'number_input',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'styles/arrows_tablet_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'right_arrow_tablet_margin_right',
            label: 'Right arrow Tablet margin right',
            type: 'number_input',
            initial_value: 4,
            conditional_fields: [
              {
                key: 'styles/arrows_tablet_switch',
                condition_value: true,
              },
            ],
          },
          // Left arrow desktop
          {
            key: 'left_arrow_desktop_margin_left',
            label: 'Left arrow Desktop margin left',
            type: 'number_input',
            initial_value: 8,
            conditional_fields: [
              {
                key: 'styles/arrows_desktop_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'left_arrow_desktop_margin_top',
            label: 'Left arrow Desktop margin top',
            type: 'number_input',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'styles/arrows_desktop_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'left_arrow_desktop_margin_bottom',
            label: 'Left arrow Desktop margin bottom',
            type: 'number_input',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'styles/arrows_desktop_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'left_arrow_desktop_margin_right',
            label: 'Left arrow Desktop margin right',
            type: 'number_input',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'styles/arrows_desktop_switch',
                condition_value: true,
              },
            ],
          },
          // Right arrow desktop
          {
            key: 'right_arrow_desktop_margin_left',
            label: 'Right arrow Desktop margin left',
            type: 'number_input',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'styles/arrows_desktop_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'right_arrow_desktop_margin_top',
            label: 'Right arrow Desktop margin top',
            type: 'number_input',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'styles/arrows_desktop_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'right_arrow_desktop_margin_bottom',
            label: 'Right arrow Desktop margin bottom',
            type: 'number_input',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'styles/arrows_desktop_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'right_arrow_desktop_margin_right',
            label: 'Right arrow Desktop margin right',
            type: 'number_input',
            initial_value: 8,
            conditional_fields: [
              {
                key: 'styles/arrows_desktop_switch',
                condition_value: true,
              },
            ],
          },
          // Left arrow large desktop
          {
            key: 'left_arrow_large_desktop_margin_left',
            label: 'Left arrow Large Desktop margin left',
            type: 'number_input',
            initial_value: 8,
            conditional_fields: [
              {
                key: 'styles/arrows_large_desktop_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'left_arrow_large_desktop_margin_top',
            label: 'Left arrow Large Desktop margin top',
            type: 'number_input',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'styles/arrows_large_desktop_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'left_arrow_large_desktop_margin_bottom',
            label: 'Left arrow Large Desktop margin bottom',
            type: 'number_input',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'styles/arrows_large_desktop_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'left_arrow_large_desktop_margin_right',
            label: 'Left arrow Large Desktop margin right',
            type: 'number_input',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'styles/arrows_large_desktop_switch',
                condition_value: true,
              },
            ],
          },
          // Right arrow large desktop
          {
            key: 'right_arrow_large_desktop_margin_left',
            label: 'Right arrow Large Desktop margin left',
            type: 'number_input',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'styles/arrows_large_desktop_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'right_arrow_large_desktop_margin_top',
            label: 'Right arrow Large Desktop margin top',
            type: 'number_input',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'styles/arrows_large_desktop_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'right_arrow_large_desktop_margin_bottom',
            label: 'Right arrow Large Desktop margin bottom',
            type: 'number_input',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'styles/arrows_large_desktop_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'right_arrow_large_desktop_margin_right',
            label: 'Right arrow Large Desktop margin right',
            type: 'number_input',
            initial_value: 8,
            conditional_fields: [
              {
                key: 'styles/arrows_large_desktop_switch',
                condition_value: true,
              },
            ],
          },
        ],
      },
      {
        group: true,
        label: 'Indicator',
        folded: false,
        fields: [
          {
            key: 'indicators_switch',
            label: 'Enable indicators',
            type: 'switch',
            initial_value: false,
          },
          {
            key: 'indicators_position',
            label: 'Position',
            type: 'select',
            options: [
              { text: 'Below', value: 'below' },
              { text: 'Top', value: 'top' },
            ],
            initial_value: 'below',
            conditional_fields: [
              {
                key: 'styles/indicators_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'indicators_height',
            label: 'Height',
            type: 'number_input',
            initial_value: 10,
            conditional_fields: [
              {
                key: 'styles/indicators_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'indicators_width',
            label: 'Width',
            type: 'number_input',
            initial_value: 10,
            conditional_fields: [
              {
                key: 'styles/indicators_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'indicator_color_default',
            label: 'Indicator color default',
            type: 'color_picker_rgba',
            initial_value: 'rgba(239, 239, 239, 0.25)',
            conditional_fields: [
              {
                key: 'styles/indicators_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'selected_indicator_color',
            label: 'Selected indicator color',
            type: 'color_picker_rgba',
            initial_value: 'rgba(239, 239, 239, 1)',
            conditional_fields: [
              {
                key: 'styles/indicators_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'hover_indicator_color',
            label: 'Hover indicator color',
            type: 'color_picker_rgba',
            initial_value: 'rgba(239, 239, 239, 0.75)',
            conditional_fields: [
              {
                key: 'styles/indicators_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'selected_hover_indicator_color',
            label: 'Selected hover indicator color',
            type: 'color_picker_rgba',
            initial_value: 'rgba(239, 239, 239, 1)',
            conditional_fields: [
              {
                key: 'styles/indicators_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'indicator_border_color_default',
            label: 'Indicator border color default',
            type: 'color_picker_rgba',
            initial_value: 'rgba(0, 0, 0, 0)',
            conditional_fields: [
              {
                key: 'styles/indicators_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'selected_indicator_border_color',
            label: 'Selected indicator border color',
            type: 'color_picker_rgba',
            initial_value: 'rgba(0, 0, 0, 0)',
            conditional_fields: [
              {
                key: 'styles/indicators_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'hover_indicator_border_color',
            label: 'Hover indicator border color',
            type: 'color_picker_rgba',
            initial_value: 'rgba(0, 0, 0, 0)',
            conditional_fields: [
              {
                key: 'styles/indicators_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'selected_hover_indicator_border_color',
            label: 'Selected hover indicator border color',
            type: 'color_picker_rgba',
            initial_value: 'rgba(0, 0, 0, 0)',
            conditional_fields: [
              {
                key: 'styles/indicators_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'indicator_border_thickness',
            label: 'Indicator border thickness',
            type: 'number_input',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'styles/indicators_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'indicator_border_radius',
            label: 'Indicator border radius',
            type: 'number_input',
            initial_value: 5,
            conditional_fields: [
              {
                key: 'styles/indicators_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'mobile_indicators_alignment',
            label: 'Mobile Alignment of indicators',
            type: 'select',
            options: [
              { text: 'Left', value: 'left' },
              { text: 'Center', value: 'center' },
              { text: 'Right', value: 'right' },
            ],
            initial_value: 'center',
            conditional_fields: [
              {
                key: 'styles/indicators_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'tablet_indicators_alignment',
            label: 'Tablet Alignment of indicators',
            type: 'select',
            options: [
              { text: 'Left', value: 'left' },
              { text: 'Center', value: 'center' },
              { text: 'Right', value: 'right' },
            ],
            initial_value: 'center',
            conditional_fields: [
              {
                key: 'styles/indicators_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'desktop_indicators_alignment',
            label: 'Desktop Alignment of indicators',
            type: 'select',
            options: [
              { text: 'Left', value: 'left' },
              { text: 'Center', value: 'center' },
              { text: 'Right', value: 'right' },
            ],
            initial_value: 'center',
            conditional_fields: [
              {
                key: 'styles/indicators_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'large_desktop_indicators_alignment',
            label: 'Large Desktop Alignment of indicators',
            type: 'select',
            options: [
              { text: 'Left', value: 'left' },
              { text: 'Center', value: 'center' },
              { text: 'Right', value: 'right' },
            ],
            initial_value: 'center',
            conditional_fields: [
              {
                key: 'styles/indicators_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'indicators_horizontal_gutter',
            label: 'Indicators horizontal gutter',
            type: 'number_input',
            initial_value: 6,
            conditional_fields: [
              {
                key: 'styles/indicators_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'indicator_arrows_switch',
            label: 'Enable indicator arrows',
            type: 'switch',
            initial_value: false,
            conditional_fields: [
              {
                key: 'styles/indicators_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'indicator_arrow_width',
            label: 'Indicator arrow width',
            type: 'number_input',
            initial_value: 20,
            conditional_fields: [
              {
                key: 'styles/indicators_switch',
                condition_value: true,
              },
              {
                key: 'styles/indicator_arrows_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'indicator_arrow_height',
            label: 'Indicator arrow height',
            type: 'number_input',
            initial_value: 20,
            conditional_fields: [
              {
                key: 'styles/indicators_switch',
                condition_value: true,
              },
              {
                key: 'styles/indicator_arrows_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'indicator_arrow_left_asset_default',
            label: 'Indicator arrow left asset default',
            type: 'uploader',
            conditional_fields: [
              {
                key: 'styles/indicators_switch',
                condition_value: true,
              },
              {
                key: 'styles/indicator_arrows_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'indicator_arrow_left_asset_hover',
            label: 'Indicator arrow left asset hover',
            type: 'uploader',
            conditional_fields: [
              {
                key: 'styles/indicators_switch',
                condition_value: true,
              },
              {
                key: 'styles/indicator_arrows_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'indicator_arrow_left_asset_inactive',
            label: 'Indicator arrow left asset inactive',
            type: 'uploader',
            conditional_fields: [
              {
                key: 'styles/indicators_switch',
                condition_value: true,
              },
              {
                key: 'styles/indicator_arrows_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'indicator_arrow_left_margin_top',
            label: 'Indicator arrow left margin top',
            type: 'number_input',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'styles/indicators_switch',
                condition_value: true,
              },
              {
                key: 'styles/indicator_arrows_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'indicator_arrow_left_margin_right',
            label: 'Indicator arrow left margin right',
            type: 'number_input',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'styles/indicators_switch',
                condition_value: true,
              },
              {
                key: 'styles/indicator_arrows_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'indicator_arrow_left_margin_bottom',
            label: 'Indicator arrow left margin bottom',
            type: 'number_input',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'styles/indicators_switch',
                condition_value: true,
              },
              {
                key: 'styles/indicator_arrows_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'indicator_arrow_left_margin_left',
            label: 'Indicator arrow left margin left',
            type: 'number_input',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'styles/indicators_switch',
                condition_value: true,
              },
              {
                key: 'styles/indicator_arrows_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'indicator_arrow_right_asset_default',
            label: 'Indicator arrow right asset default',
            type: 'uploader',
            conditional_fields: [
              {
                key: 'styles/indicators_switch',
                condition_value: true,
              },
              {
                key: 'styles/indicator_arrows_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'indicator_arrow_right_asset_hover',
            label: 'Indicator arrow right asset hover',
            type: 'uploader',
            conditional_fields: [
              {
                key: 'styles/indicators_switch',
                condition_value: true,
              },
              {
                key: 'styles/indicator_arrows_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'indicator_arrow_right_asset_inactive',
            label: 'Indicator arrow right asset inactive',
            type: 'uploader',
            conditional_fields: [
              {
                key: 'styles/indicators_switch',
                condition_value: true,
              },
              {
                key: 'styles/indicator_arrows_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'indicator_arrow_right_margin_top',
            label: 'Indicator arrow right margin top',
            type: 'number_input',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'styles/indicators_switch',
                condition_value: true,
              },
              {
                key: 'styles/indicator_arrows_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'indicator_arrow_right_margin_right',
            label: 'Indicator arrow right margin right',
            type: 'number_input',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'styles/indicators_switch',
                condition_value: true,
              },
              {
                key: 'styles/indicator_arrows_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'indicator_arrow_right_margin_bottom',
            label: 'Indicator arrow right margin bottom',
            type: 'number_input',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'styles/indicators_switch',
                condition_value: true,
              },
              {
                key: 'styles/indicator_arrows_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'indicator_arrow_right_margin_left',
            label: 'Indicator arrow right margin left',
            type: 'number_input',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'styles/indicators_switch',
                condition_value: true,
              },
              {
                key: 'styles/indicator_arrows_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'indicators_background_color',
            label: 'Indicators background color',
            type: 'color_picker_rgba',
            initial_value: 'rgba(0, 0, 0, 0)',
            conditional_fields: [
              {
                key: 'styles/indicators_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'indicators_border_color',
            label: 'Indicators border color',
            type: 'color_picker_rgba',
            initial_value: 'rgba(0, 0, 0, 0)',
            conditional_fields: [
              {
                key: 'styles/indicators_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'indicators_border_thickness',
            label: 'Indicators border thickness',
            type: 'number_input',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'styles/indicators_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'indicators_corner_radius',
            label: 'Indicators corner radius',
            type: 'number_input',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'styles/indicators_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'indicators_background_blur_switch',
            label: 'Enable background blur',
            type: 'switch',
            initial_value: false,
            conditional_fields: [
              {
                key: 'styles/indicators_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'indicators_background_blur',
            label: 'Background blur',
            type: 'number_input',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'styles/indicators_switch',
                condition_value: true,
              },
              {
                key: 'styles/indicators_background_blur_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'indicators_padding_top',
            label: 'Indicators padding top',
            type: 'number_input',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'styles/indicators_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'indicators_padding_right',
            label: 'Indicators padding right',
            type: 'number_input',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'styles/indicators_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'indicators_padding_bottom',
            label: 'Indicators padding bottom',
            type: 'number_input',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'styles/indicators_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'indicators_padding_left',
            label: 'Indicators padding left',
            type: 'number_input',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'styles/indicators_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'mobile_indicators_margin_top',
            label: 'Mobile Indicators margin top',
            type: 'number_input',
            initial_value: 16,
            conditional_fields: [
              {
                key: 'styles/indicators_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'mobile_indicators_margin_right',
            label: 'Mobile Indicators margin right',
            type: 'number_input',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'styles/indicators_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'mobile_indicators_margin_bottom',
            label: 'Mobile Indicators margin bottom',
            type: 'number_input',
            initial_value: 16,
            conditional_fields: [
              {
                key: 'styles/indicators_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'mobile_indicators_margin_left',
            label: 'Mobile Indicators margin left',
            type: 'number_input',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'styles/indicators_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'tablet_indicators_margin_top',
            label: 'Tablet Indicators margin top',
            type: 'number_input',
            initial_value: 16,
            conditional_fields: [
              {
                key: 'styles/indicators_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'tablet_indicators_margin_right',
            label: 'Tablet Indicators margin right',
            type: 'number_input',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'styles/indicators_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'tablet_indicators_margin_bottom',
            label: 'Tablet Indicators margin bottom',
            type: 'number_input',
            initial_value: 16,
            conditional_fields: [
              {
                key: 'styles/indicators_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'tablet_indicators_margin_left',
            label: 'Tablet Indicators margin left',
            type: 'number_input',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'styles/indicators_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'desktop_indicators_margin_top',
            label: 'Desktop Indicators margin top',
            type: 'number_input',
            initial_value: 16,
            conditional_fields: [
              {
                key: 'styles/indicators_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'desktop_indicators_margin_right',
            label: 'Desktop Indicators margin right',
            type: 'number_input',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'styles/indicators_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'desktop_indicators_margin_bottom',
            label: 'Desktop Indicators margin bottom',
            type: 'number_input',
            initial_value: 16,
            conditional_fields: [
              {
                key: 'styles/indicators_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'desktop_indicators_margin_left',
            label: 'Desktop Indicators margin left',
            type: 'number_input',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'styles/indicators_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'large_desktop_indicators_margin_top',
            label: 'Large Desktop Indicators margin top',
            type: 'number_input',
            initial_value: 16,
            conditional_fields: [
              {
                key: 'styles/indicators_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'large_desktop_indicators_margin_right',
            label: 'Large Desktop Indicators margin right',
            type: 'number_input',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'styles/indicators_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'large_desktop_indicators_margin_bottom',
            label: 'Large Desktop Indicators margin bottom',
            type: 'number_input',
            initial_value: 16,
            conditional_fields: [
              {
                key: 'styles/indicators_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'large_desktop_indicators_margin_left',
            label: 'Large Desktop Indicators margin left',
            type: 'number_input',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'styles/indicators_switch',
                condition_value: true,
              },
            ],
          },
        ],
      },
      {
        group: true,
        label: 'Effects',
        folded: false,
        fields: [
          {
            key: 'off_screen_transparency_mobile_switch',
            label: 'Enable Mobile off screen transparency',
            type: 'switch',
            initial_value: false,
          },
          {
            key: 'off_screen_transparency_tablet_switch',
            label: 'Enable Tablet off screen transparency',
            type: 'switch',
            initial_value: true,
          },
          {
            key: 'off_screen_transparency_desktop_switch',
            label: 'Enable Desktop off screen transparency',
            type: 'switch',
            initial_value: true,
          },
          {
            key: 'off_screen_transparency_large_desktop_switch',
            label: 'Enable Large Desktop off screen transparency',
            type: 'switch',
            initial_value: true,
          },
          {
            key: 'off_screen_opacity',
            label: 'Off screen opacity',
            type: 'number_input',
            initial_value: 50,
          },
        ],
      },
    ],
  },
} as const;

export default manifest;
