import { useFetcher, useLoaderData } from '@remix-run/react';
import localforage from 'localforage';
import type { FeedEntry } from '~/services/layout';
import { LANGUAGE_SELECTOR_CLIENT_STORAGE_KEY } from '~/utils/i18n';
import { useEffect, useState } from 'react';
import type { loader } from '~/routes/_index';
import get from 'lodash.get';

/**
 * Selected state action type.
 * @property endpoint - Endpoint to be called when the cell is clicked.
 * @property clientStorageKey - Client storage key to store the selected
 * state value.
 * @property loaderDataKey - Optionally provide a loader data key to get the
 * supported feed entry extentions tags to prevent the cell from being clickable.
 */
type selectedStateAction = {
  endpoint: string;
  clientStorageKey?: string;
  loaderDataKey?: string;
};

/**
 * Selected state actions object.
 * @property language_selector - Language selector selected state role name.
 * To add support for a new selected state action, add a new key with the role
 * the role should be available in the feed entry under the _role property.
 * @property endpoint - Endpoint to be called when the cell is clicked.
 * The endpoint should be available in the API routes.
 * The endpoint should handle all relevant actions for the selected state.
 * @property clientStorageKey - Client storage key to store the selected state value.
 * @property loaderDataKey - Optionally provide a loader data key to get the supported
 * feed entry extentions tags to prevent the cell from being unnecessarily clickable.
 * The loader data key should return an array of supported feed entry extentions tags (string[]).
 */
const selectedStateActions: {
  language_selector: selectedStateAction;
} = {
  language_selector: {
    endpoint: '/api/language-selector',
    clientStorageKey: LANGUAGE_SELECTOR_CLIENT_STORAGE_KEY,
    loaderDataKey: 'supportedLanguages',
  },
};

/**
 * Selected state hook to determine if the cell should act as selected state
 * action cell based on the feed entry.
 * @param feedEntry - Feed entry
 * @returns - The selected/unselected states and the action function to be
 * called when the cell is clicked.
 */
export function useSelectedState(feedEntry: FeedEntry): {
  isSelectedStateCellAndUnselected: boolean;
  isSelectedStateCellAndSelected: boolean;
  selectedStateActionFunction: Function;
} {
  const fetcher: any = useFetcher({
    key: 'selected-state',
  });

  const role: string | undefined = feedEntry?._role;
  const feedEntryExtentionsTag: string | undefined = feedEntry?.extensions?.tag;

  const selectedStateAction: selectedStateAction | undefined =
    selectedStateActions[role as keyof typeof selectedStateActions];

  const selectedStateActionEndpoint: string | undefined =
    selectedStateAction?.endpoint;

  const selectedStateClientStorageKey: string | undefined =
    selectedStateAction?.clientStorageKey;

  const loaderDataKey: string | undefined = selectedStateAction?.loaderDataKey;

  const isSupportedFeedEntryExtentionsTag: boolean =
    useIsSupportedFeedEntryExtentionsTag({
      loaderDataKey,
      feedEntryExtentionsTag,
    });

  const isSelectedStateCell: boolean = [
    role,
    selectedStateAction,
    feedEntryExtentionsTag,
    selectedStateActionEndpoint,
    selectedStateClientStorageKey,
    isSupportedFeedEntryExtentionsTag,
  ].every(Boolean);

  const [currentSelectedStateValue, setCurrentSelectedStateValue] =
    useState<any>(undefined);

  const [
    isCurrentSelectedStateValueSelected,
    setIsCurrentSelectedStateValueSelected,
  ] = useState<boolean>(false);

  useEffect(() => {
    if (!isSelectedStateCell) return;

    (async () => {
      setCurrentSelectedStateValue(
        await getCurrentSelectedStateValue(selectedStateClientStorageKey!)
      );
    })();
  }, []);

  useEffect(() => {
    if (!isSelectedStateCell || !currentSelectedStateValue) return;

    setIsCurrentSelectedStateValueSelected(
      currentSelectedStateValue === feedEntryExtentionsTag
    );
  }, [currentSelectedStateValue]);

  const selectedStateActionFunction: Function = () => {
    if (!isSelectedStateCell) return;

    fetcher.submit(
      { role, feedEntryExtentionsTag },
      {
        method: 'post',
        action: selectedStateActionEndpoint,
      }
    );
  };

  return {
    isSelectedStateCellAndSelected: !!(
      isSelectedStateCell && isCurrentSelectedStateValueSelected
    ),
    isSelectedStateCellAndUnselected:
      isSelectedStateCell && !isCurrentSelectedStateValueSelected,
    selectedStateActionFunction,
  };
}

async function getCurrentSelectedStateValue(
  clientStorageKey: string
): Promise<any> {
  try {
    return await localforage.getItem<string>(clientStorageKey);
  } catch (error) {
    return null;
  }
}

/**
 * Is supported feed entry extentions tag to validate if the feed entry
 * extentions tag is supported by the loader data, if the loader data key
 * contains the supported feed entry extentions tags.
 * @param loaderDataKey - Loader data key
 * @param feedEntryExtentionsTag - Feed entry extentions tag
 * @returns A boolean indicating if the feed entry extentions tag is supported.
 * Return true if loaderDataKey is undefined or if data is not available from
 * the loader.
 * Return true if the feed entry extentions tag is supported.
 * Return false if the feed entry extentions tag is not supported to prevent
 * the cell from being clickable.
 */
function useIsSupportedFeedEntryExtentionsTag({
  loaderDataKey,
  feedEntryExtentionsTag,
}: {
  loaderDataKey: string | undefined;
  feedEntryExtentionsTag: string | undefined;
}): boolean {
  const loaderData = useLoaderData<typeof loader>();

  if (!loaderDataKey || !feedEntryExtentionsTag) return true;

  const supportedFeedEntryExtentionsTags: string[] | undefined = get(
    loaderData,
    loaderDataKey
  );

  if (!supportedFeedEntryExtentionsTags) return true;
  if (!Array.isArray(supportedFeedEntryExtentionsTags)) return true;
  if (supportedFeedEntryExtentionsTags.length === 0) return true;

  const isSupportedFeedEntryExtentionsTags =
    supportedFeedEntryExtentionsTags.find(
      (tag) => tag === feedEntryExtentionsTag
    );

  if (isSupportedFeedEntryExtentionsTags) return true;

  return false;
}
