import { useEffect } from 'react';
import { useFetcher } from '@remix-run/react';
import type { UIComponent } from '~/services/layout/index.ts';
import { useClientFeedApiEndpoint } from '~/hooks/use-client-feed-api-endpoint';

export type Props = {
  Component: (props: any) => JSX.Element;
  uiComponent: UIComponent;
};

function ClientUiComponent({ Component, uiComponent }: Props): JSX.Element {
  const fetcher = useFetcher();

  const uiComponentDataSource: string | undefined = uiComponent?.data?.source;

  const clientFeedApiEndpoint: string | null = useClientFeedApiEndpoint({
    uiComponent,
  });

  const callClientFeedApiEndpoint = [
    uiComponentDataSource,
    clientFeedApiEndpoint,
    fetcher.state === 'idle' && !fetcher.data,
  ].every(Boolean);

  useEffect(() => {
    if (!callClientFeedApiEndpoint) return;
    fetcher.load(clientFeedApiEndpoint!);
  }, [fetcher.data]);

  return (
    <Component
      isClient={true}
      isLoading={fetcher.state !== 'idle' && !fetcher.data}
      uiComponent={uiComponent}
      feed={fetcher.data}
    />
  );
}

export default ClientUiComponent;
