import { twMerge } from 'tailwind-merge';
import {
  getCellVisibillityClasses,
  getCellVisibillityClassesFromConfig,
  getCellBreakpointValues,
  shouldRenderByConfig,
  type ResponsiveSources,
} from './power-cell-getters';
import type { loader as rootLoader } from '~/routes/_index.ts';
import { useLoaderData } from '@remix-run/react';
import { type NavigateFunction, useNavigate } from '@remix-run/react';
import { ButtonsContainerButtonBorder } from './power-cell-border';
import { type UseResponsiveBreakpoint } from '~/hooks/use-responsive-breakpoint';
import type { FeedEntry } from '~/services/layout/index';
import { ResponsiveText } from './responsive-text';
import { ResponsiveImage } from './responsive-image';
import {
  useFavoriteAction,
  type UseFavoriteActionReturnType,
} from '~/hooks/use-favorite-action';
import {
  getButtonsContainerAssetAlignmentClasses,
  getButtonsContainerButtonAction,
  getButtonsContainerStackingClasses,
  getButtonsContainerVisibillity,
  getButtonsContainerButtonData,
  onButtonMouseEnter,
  onButtonMouseLeave,
  getButtonContainerAlignmentClasses,
  type ButtonsContainerButtonAction,
  type AssetAlignmentResponsive,
  type ButtonsContainerButtonData,
  type ButtonNumber,
  type ButtonContainerButtonAction,
  type ButtonContainerButtonData,
  type ButtonName,
  getFavoriteButtonIcons,
  getButtonsContainerButtonFillModeClasses,
  getButtonsContainerButtonContentAlignmentClasses,
} from './buttons-container-getters';
import { useTrailerLink } from '~/hooks/use-trailer-link';

export function ButtonsContainer({
  assets,
  textLabelKeyName,
  entryWebLink,
  trackAnalyticsData,
  responsiveBreakpoint,
  entry,
}: {
  assets: any;
  textLabelKeyName: string;
  entryWebLink: string;
  trackAnalyticsData: Function;
  responsiveBreakpoint: UseResponsiveBreakpoint;
  entry: FeedEntry;
}) {
  const { navigationAction, trailerAction, favoritesAction } =
    useLoaderData<typeof rootLoader>();
  const navigate: NavigateFunction = useNavigate();
  const {
    isFavorite,
    favoriteAction,
    shouldRenderFavoriteAction,
  }: UseFavoriteActionReturnType = useFavoriteAction({
    entry,
  });

  if (
    !shouldRenderByConfig({
      config: assets,
      key: 'buttons_container_switch',
    })
  )
    return null;

  const entryTrailerLink: string = entry?.extensions?.trailer_feed_url || '';

  if (!entryWebLink && !entryTrailerLink && !shouldRenderFavoriteAction)
    return null;

  const buttonsAction: ButtonsContainerButtonAction =
    getButtonsContainerButtonAction({
      assets,
      responsiveBreakpoint,
    });

  const buttonsContainerButtonData: ButtonsContainerButtonData =
    getButtonsContainerButtonData({
      assets,
      navigationAction,
      trailerAction,
      favoritesAction,
      favorite: !!isFavorite,
    });

  const favoriteIcons: ResponsiveSources = getFavoriteButtonIcons({
    isFavorite,
    favoritesAction,
  });

  return (
    <div
      className={twMerge(
        getCellVisibillityClasses(
          getButtonsContainerVisibillity({
            assets,
            textLabelKeyName,
          })
        ),
        'mb-buttons-container-b ml-buttons-container-l mr-buttons-container-r mt-buttons-container-t'
      )}
    >
      <div
        className={twMerge(
          getButtonsContainerStackingClasses(assets),
          getButtonContainerAlignmentClasses(assets),
          'z-[3] flex gap-buttons-container-gutter'
        )}
      >
        <ButtonsContainerButton
          {...{
            buttonNumber: 1,
            buttonsContainerButtonData,
            buttonsAction,
            assets,
            entryWebLink,
            entryTrailerLink,
            shouldRenderFavoriteAction,
            favoriteAction,
            favoriteIcons,
            trackAnalyticsData,
            navigate,
          }}
        />
        <ButtonsContainerButton
          {...{
            buttonNumber: 2,
            buttonsContainerButtonData,
            buttonsAction,
            assets,
            entryWebLink,
            entryTrailerLink,
            shouldRenderFavoriteAction,
            favoriteAction,
            favoriteIcons,
            trackAnalyticsData,
            navigate,
          }}
        />
        <ButtonsContainerButton
          {...{
            buttonNumber: 3,
            buttonsContainerButtonData,
            buttonsAction,
            assets,
            entryWebLink,
            entryTrailerLink,
            shouldRenderFavoriteAction,
            favoriteAction,
            favoriteIcons,
            trackAnalyticsData,
            navigate,
          }}
        />
      </div>
    </div>
  );
}

type ButtonContainerButtonClasses = {
  button: string;
  imageClassNames: string;
  assetClassNames: string;
};
type ButtonsContainerButtonClasses = {
  button1: ButtonContainerButtonClasses;
  button2: ButtonContainerButtonClasses;
  button3: ButtonContainerButtonClasses;
};

const buttonsClasses: ButtonsContainerButtonClasses = {
  button1: {
    button:
      'group-hover-button-asset pointer-events-auto relative mb-button1_margin_bottom ml-button1_margin_left mr-button1_margin_right mt-button1_margin_top w-button1_width flex-row-reverse rounded-button1_corner_radius bg-button1_background_color pb-button1_padding_bottom pl-button1_padding_left pr-button1_padding_right pt-button1_padding_top font-button1_font_family text-button1_font_size leading-button1_line_height tracking-button1_letter_spacing text-button1_font_color button1-text-transform button1-default-text-decoration hover:bg-button1_hover_background_color hover:text-button1_hover_font_color hover:button1_hover_text_decoration',
    imageClassNames: 'w-button1_asset_width h-button1_asset_height',
    assetClassNames:
      'mt-button1_asset_margin_top ml-button1_asset_margin_left mr-button1_asset_margin_right mb-button1_asset_margin_bottom',
  },
  button2: {
    button:
      'group-hover-button-asset pointer-events-auto relative mb-button2_margin_bottom ml-button2_margin_left mr-button2_margin_right mt-button2_margin_top w-button2_width flex-row-reverse rounded-button2_corner_radius bg-button2_background_color pb-button2_padding_bottom pl-button2_padding_left pr-button2_padding_right pt-button2_padding_top font-button2_font_family text-button2_font_size leading-button2_line_height tracking-button2_letter_spacing text-button2_font_color button2-text-transform button2-default-text-decoration hover:bg-button2_hover_background_color hover:text-button2_hover_font_color hover:button2_hover_text_decoration',
    imageClassNames: 'w-button2_asset_width h-button2_asset_height',
    assetClassNames:
      'mt-button2_asset_margin_top ml-button2_asset_margin_left mr-button2_asset_margin_right mb-button2_asset_margin_bottom',
  },
  button3: {
    button:
      'group-hover-button-asset pointer-events-auto relative mb-button3_margin_bottom ml-button3_margin_left mr-button3_margin_right mt-button3_margin_top w-button3_width flex-row-reverse rounded-button3_corner_radius bg-button3_background_color pb-button3_padding_bottom pl-button3_padding_left pr-button3_padding_right pt-button3_padding_top font-button3_font_family text-button3_font_size leading-button3_line_height tracking-button3_letter_spacing text-button3_font_color button3-text-transform button3-default-text-decoration hover:bg-button3_hover_background_color hover:text-button3_hover_font_color hover:button3_hover_text_decoration',
    imageClassNames: 'w-button3_asset_width h-button3_asset_height',
    assetClassNames:
      'mt-button3_asset_margin_top ml-button3_asset_margin_left mr-button3_asset_margin_right mb-button3_asset_margin_bottom',
  },
};

function ButtonsContainerButton({
  buttonNumber,
  buttonsContainerButtonData,
  buttonsAction,
  assets,
  entryWebLink,
  entryTrailerLink,
  shouldRenderFavoriteAction,
  favoriteAction,
  favoriteIcons,
  trackAnalyticsData,
  navigate,
}: {
  buttonNumber: ButtonNumber;
  assets: any;
  buttonsContainerButtonData: ButtonsContainerButtonData;
  buttonsAction: any;
  entryWebLink: string;
  entryTrailerLink: string;
  shouldRenderFavoriteAction: boolean;
  favoriteAction: Function;
  favoriteIcons: ResponsiveSources;
  trackAnalyticsData: Function;
  navigate: Function;
}): JSX.Element {
  const buttonName: ButtonName = `button${buttonNumber}`;
  const buttonActionLink: ButtonContainerButtonAction =
    buttonsAction[`${buttonName}`];
  const isUnsetAction: boolean = buttonActionLink === 'unset';
  const isPlayAction: boolean = buttonActionLink === 'play';
  const isTrailerAction: boolean = buttonActionLink === 'trailer';
  const isFavoriteAction: boolean = buttonActionLink === 'favorite';

  const buttonData: ButtonContainerButtonData =
    buttonsContainerButtonData[`${buttonName}`];

  const classes: ButtonContainerButtonClasses = buttonsClasses[buttonName];

  const { debouncedTrailerLinkAction } = useTrailerLink({
    entryTrailerLink,
  });

  if (isPlayAction && !entryWebLink) return <></>;
  if (isTrailerAction && !entryTrailerLink) return <></>;
  if (isFavoriteAction && !shouldRenderFavoriteAction) return <></>;

  const assetAlignment = getCellBreakpointValues({
    config: assets,
    key: `${buttonName}_asset_alignment`,
  }) as AssetAlignmentResponsive;

  return (
    <div
      className={
        getCellVisibillityClassesFromConfig({
          config: assets,
          key: `${buttonName}_switch`,
        }) +
        ' z-40 ' +
        getButtonsContainerButtonFillModeClasses(assets, buttonNumber)
      }
    >
      <button
        className={classes.button}
        onClick={(event) => {
          if (isUnsetAction) return;

          event.preventDefault();
          event.stopPropagation();
          trackAnalyticsData();

          if (isPlayAction) {
            navigate(entryWebLink);
            return;
          }

          if (isFavoriteAction) {
            favoriteAction();
            return;
          }

          if (isTrailerAction) debouncedTrailerLinkAction();
        }}
        onMouseEnter={(event) => {
          if (isUnsetAction) return;

          onButtonMouseEnter({
            event,
            buttonLink: isPlayAction ? entryWebLink : entryTrailerLink,
            isNonDirectLinkAction: isTrailerAction || isFavoriteAction,
          });
        }}
        onMouseLeave={(event) => {
          if (isUnsetAction) return;

          onButtonMouseLeave({
            event,
            parentLink: entryWebLink,
          });
        }}
      >
        <div
          className={
            getButtonsContainerAssetAlignmentClasses(assetAlignment) +
            ' ' +
            getButtonsContainerButtonContentAlignmentClasses({
              assets,
              buttonNumber,
              assetAlignment,
            })
          }
        >
          <div
            className={getCellVisibillityClassesFromConfig({
              config: assets,
              key: `${buttonName}_enable_label_1`,
            })}
          >
            <ResponsiveText responsiveText={buttonData.labels} />
          </div>
          <ButtonAsset
            assets={assets}
            initialAssets={buttonData.assets.default}
            hoverAssets={buttonData.assets.hover}
            favoriteIcons={favoriteIcons}
            isFavoriteAction={isFavoriteAction}
            buttonNumber={buttonNumber}
            imageClassNames={classes.imageClassNames}
            assetClassNames={classes.assetClassNames}
          />
        </div>
        <ButtonsContainerButtonBorder buttonNumber={buttonNumber} />
      </button>
    </div>
  );
}

/**
 *
 * @param assets - cell assets for each breakpoint
 * @param initialAssets - initial assets for the button for each breakpoint
 * @param hoverAssets - hover assets for the button for each breakpoint
 * @param favoriteIcons - favorite icons for each breakpoint
 * @param isFavoriteAction - is the button a favorite action for the current breakpoint
 * @param buttonNumber - the button number (1, 2, 3)
 * @param assetClassNames - asset class names
 * @param imageClassNames - image class names
 * @returns - The button asset and hover asset JSX element for the button
 */
function ButtonAsset({
  assets,
  initialAssets,
  hoverAssets,
  favoriteIcons,
  isFavoriteAction,
  buttonNumber,
  assetClassNames,
  imageClassNames,
}: {
  assets: any;
  initialAssets: ResponsiveSources;
  hoverAssets: ResponsiveSources;
  favoriteIcons: ResponsiveSources;
  isFavoriteAction: boolean;
  buttonNumber: ButtonNumber;
  assetClassNames: string;
  imageClassNames: string;
}): JSX.Element {
  return (
    <div
      className={twMerge(
        getCellVisibillityClassesFromConfig({
          config: assets,
          key: `button${buttonNumber}_enable_asset`,
        }),
        assetClassNames,
        'shrink-0'
      )}
    >
      {isFavoriteAction ? (
        <ResponsiveImage
          imageSources={favoriteIcons}
          altText="favorite button icon"
          classNames={imageClassNames}
        />
      ) : (
        <>
          <ResponsiveImage
            imageSources={initialAssets}
            altText={'action button icon'}
            classNames={`block group-hover-button-asset:hidden ${imageClassNames}`}
          />
          <ResponsiveImage
            imageSources={hoverAssets}
            altText={'action button icon'}
            classNames={`hidden group-hover-button-asset:block ${imageClassNames}`}
            isHoverImage={true}
          />
        </>
      )}
    </div>
  );
}
