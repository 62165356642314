import { useEffect } from 'react';
import { useLocation, useNavigate } from '@remix-run/react';
import localforage from 'localforage';
import logger from '~/services/logger';
import { getURLSegments } from '~/utils/validations';
import {
  LANGUAGE_SELECTOR_DEFAULT_LANGUAGE,
  LANGUAGE_SELECTOR_CLIENT_STORAGE_KEY,
} from '~/utils/i18n';

export async function getStoredLanguage(supportedLanguages: string[]) {
  try {
    const storedLanguage = await localforage.getItem<string>(
      LANGUAGE_SELECTOR_CLIENT_STORAGE_KEY
    );

    if (storedLanguage) {
      return storedLanguage;
    }

    const browserLanguage = window.navigator?.language;
    const language = browserLanguage?.split('-')?.[0]; // 'en-GB' -> 'en'

    if (supportedLanguages.includes(language)) {
      await localforage.setItem(LANGUAGE_SELECTOR_CLIENT_STORAGE_KEY, language);
    } else {
      await localforage.setItem(
        LANGUAGE_SELECTOR_CLIENT_STORAGE_KEY,
        supportedLanguages[0]
      );
    }

    return language;
  } catch (err) {
    logger.info(
      `Failed to get user's language, returning default language: ${err}`
    );

    return LANGUAGE_SELECTOR_DEFAULT_LANGUAGE;
  }
}

export function useLanguageRedirect(
  supportedLanguages: string[],
  language: string
) {
  const { pathname } = useLocation();

  const navigate = useNavigate();

  const segments = getURLSegments(pathname);
  const urlLanguage = segments?.[0];

  const defaultLanguage =
    supportedLanguages?.[0] || LANGUAGE_SELECTOR_DEFAULT_LANGUAGE;

  const path = pathname?.replace(/\/$/, '');

  useEffect(() => {
    async function checkLanguage() {
      if (supportedLanguages?.length === 0) return;

      const storedLanguage = await getStoredLanguage(supportedLanguages);

      if (storedLanguage === defaultLanguage) {
        return;
      }

      if (
        storedLanguage === 'unknown' ||
        !supportedLanguages.includes(storedLanguage)
      ) {
        return;
      }

      if (storedLanguage !== urlLanguage) {
        navigate(`/${storedLanguage}${path}`);
        return;
      }
    }

    checkLanguage();
  }, [language, supportedLanguages]);
}
