import get from 'lodash.get';
import type { FeedEntry, UIComponent } from '../layout/index.ts';
import logger from '~/services/logger';
import { isValidUrl } from '~/utils/validations.ts';

export function getComputedUrl(
  component: UIComponent,
  dynamicIdFallback: string | undefined | null,
  q: string | null,
  entry?: FeedEntry | {}
): string | undefined {
  if (component?.component_type === 'epg_component') {
    const channelsFeedUrl: string | undefined =
      component?.data?.channels_source?.source;

    if (!isValidUrl(channelsFeedUrl)) return undefined;

    return channelsFeedUrl;
  }

  if (component?.data?.mapping) {
    return Object.keys(component?.data?.mapping).reduce((acc, key) => {
      const value =
        component?.data?.mapping?.[key]?.source === 'search'
          ? q
          : get(entry, component?.data?.mapping?.[key]?.property);

      return acc.replace(`{{${key}}}`, value || dynamicIdFallback);
    }, component?.data?.source);
  }

  return component?.data?.source;
}

export function getDynamicIdFallback(request: {
  url: string;
}): string | undefined {
  try {
    const requestUrl: URL = new URL(request.url);

    const urlSegments = requestUrl.pathname.slice(1).split('/');

    // FIXME: Video identifier should always be on the second place in the URL segments
    return urlSegments[1];
  } catch (error: any) {
    logger.info(`Error in getDynamicIdFallback: ${error.message}`);
    return undefined;
  }
}

export const JWT_PRIVATE = `
-----BEGIN PRIVATE KEY-----
MIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQClB4jTIVNL0p3r
DY5no6xyY1iiBMsqekWjnTLj0yek/zA0jp0wP+tFZqbkiggRRa3Uqves9jsHHo+m
c4qVQ1bVUgKqVEFpNCY/kPu3pwd87ipZF7WLO36mkrNHwnPVrKCjPnPG2RdbXGwh
OWnAwxpw0o0SI24fUPAZCpru7oi3iXRl9iGUFaQ6zVlXHvSFAG6SH6J1O7i0w0KN
Bgry2jszMdEbiwFrvqdmOtlal0d4pNv7xZb1wkYXv5oBAXNaYYWaEUMBRPHhXtwS
jGr68BwH6TM3UNiXP/i9sFxcqbpbzQQNJ/hf/iz2Ap7cDmeCeTcy60qu+r0Zu9B3
I9R84DwPAgMBAAECggEAFy6Fw9KslrwRPtF6rYhKUw8thiTSPTdLzP3qBiLFMOI/
7UITRpHUBL20t+nD1FlLX9JcVEmKZSef2ivkza7V5cVNZovLXHHc5ZUCJvwpix0R
ic5QzCwhUFjPIkdoah2DuyqsrMQWCdljvw8HkfJWJn8NHf0oHPILqBjbRrDvN4i3
XKJX9mqXOdAoVuweQg3rjWBV2zD2Jvkj+c/LQqrZdCy/Yr/f3n/r8KwEv+MaXWMD
bwhRLQ9YZ4LJivRfG+9jUWz6/PDY9jEqVUHjzNuzsL4FEZbeTTi/81gnLuuoGCbu
BSJ2CQ0BvBLHR6TjiJaul6W86hBI16t3yZ5dT9elcQKBgQC36caJU/urzz5OwMQ/
yaPkjc+FATevm9h7laxq1iZmc/n42NZzHo7JVg1CMUtCTE8AeOqPfijYVk9FbZSz
1025fmJUJa4MVJVZOwEw8iKtizqBlEMNyptGoorGMtXoCtNYFrZWHNP70Cb3KKsB
lOc7WqMCl08gdbu+WCnk90CbcwKBgQDltuysFYo2lmZwAC28SnTMcRnMtkmEnz/J
m/rFQC6u5k9+ZjV00at9RmedK6MNVclZUCQMC4XsJZHkoNSjBzT3E5xYh7moydmR
ynfJjugAMDf1jghmhXs6sjJdElWLlAUDOOQ5xg5APtC3e+EAcYpxWiLkYPBT87Bx
o491/tbt9QKBgDsWoPL+SJ2quBoVVPgpkL6d0U6qVXnWWHdcX0j0BOJl4KpTAJN7
/eQyAZCCxetHGRrNvo7bucPH9zAXRO+C/TXbpTvj30eW5WUF0Bwpr3qSgPmg6OpF
bh4DmxKSwqcK01w579s65+C/v8UpC8g2TxSCylzKwGFtciR1rwAhcGCBAoGBALpQ
codkdRSvSeh/jJjSfwcumLFcDCcADvmThIKl+vcqn8EyXxsf0Aqspu9obDgto0DI
amRGmFmfY0RfWffcqY3Ynw+DDgejp87CvAOD4BH/CfQlPhUBIKDL1WN9slqiNKqX
YyykwfxDtn+Lf0Qf0xz68Z/wLBy7YxnYgkw+5M5dAoGAUZ/8/87orIMMdV7zkHUF
c7syzUizac3pXL8twLuetCkNDsEeNyDV725PQuzDWNhH7slHeml+/acVRty0wUqp
fhJP3APu7/Ib0kQqjyjr0eICU2YcJJ6fnPyELGPh4E467QOmrvsEWLLkyHGVmW6o
JR7lkhRrC5OZsPPOxlbH8bI=
-----END PRIVATE KEY-----`;
