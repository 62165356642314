import { useLoaderData } from '@remix-run/react';
import React from 'react';
import DebugComponent from '~/components/debug.tsx';
import UiComponent from '~/components/ui-component.tsx';
import Search from '~/components/screens/search.tsx';
import type { loader as rootLoader } from '~/routes/_index.tsx';
import { twMerge } from 'tailwind-merge';
import type { Feed, ScreenType } from '~/services/layout/index.ts';
import { SearchV2 } from '../../search_v2/components';
import { getSearchScreenPadding } from '~/services/css-vars/get-search-v2-vars';

export default function GeneralContent(): JSX.Element {
  const {
    debug,
    serverLoadedFeeds,
    uiComponents,
    isSearchScreen,
    searchScreens,
    screen,
    isThemeV2,
  } = useLoaderData<typeof rootLoader>();

  const searchScreenType: ScreenType | undefined = searchScreens?.[0]?.type;

  const isSearchScreenV1: boolean =
    isSearchScreen && searchScreenType === 'qb_search_screen';

  const isSearchScreenV2: boolean =
    isSearchScreen && searchScreenType === 'search_v2';

  const searchScreenV2BackgroundColor: string | undefined =
    isSearchScreenV2 && searchScreens?.[0].styles?.screen_background_color;

  return (
    <div
      className={twMerge(
        `screen-${screen.id}`,
        'z-0',
        isThemeV2
          ? 'screen-classes'
          : 'mb-screen-b mt-screen-t min-h-[50vh] bg-screen pb-mobile-screen-b pl-mobile-screen-l pr-mobile-screen-r pt-mobile-screen-t lg:pb-screen-b lg:pl-screen-l lg:pr-screen-r lg:pt-screen-t',
        isSearchScreenV2 && getSearchScreenPadding({ searchScreens })
      )}
      style={{
        backgroundColor: searchScreenV2BackgroundColor
          ? searchScreenV2BackgroundColor
          : undefined,
      }}
    >
      {isSearchScreenV1 && <Search />}
      {isSearchScreenV2 && <SearchV2 />}

      {React.Children.toArray(
        uiComponents.map((uiComponent: any) => {
          const feed = serverLoadedFeeds.find(
            (feed: any) => feed.componentId === uiComponent.id
          )?.feed as Feed | null | undefined;

          const error = serverLoadedFeeds.find(
            (feed: any) => feed.componentId === uiComponent.id
          )?.error;

          return (
            <div className="truncate">
              {debug === 'on' && (
                <DebugComponent
                  uiComponent={uiComponent}
                  feed={feed}
                  error={error}
                />
              )}
              <UiComponent uiComponent={uiComponent} feed={feed} />
            </div>
          );
        })
      )}
    </div>
  );
}
