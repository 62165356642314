import logger from '~/services/logger';
import { twMerge } from 'tailwind-merge';
import { type FeedEntry } from '~/services/layout/index.ts';
import { ResponsiveImage } from './responsive-image';
import {
  useFavoriteAction,
  type UseFavoriteActionReturnType,
} from '~/hooks/use-favorite-action.ts';
import {
  getCellAssetSources,
  type ResponsiveSources,
} from './power-cell-getters';
import { useLoaderData } from '@remix-run/react';
import { loader } from '~/routes/_index';

/**
 * ActionIcon component - renders the favorite icon for a cell
 * @param assets cell assets for each breakpoint
 * @param entry feed entry
 * @returns JSX.Element
 */
export function ActionIconV2({
  assets,
  entry,
}: {
  assets: any;
  entry: FeedEntry;
}): JSX.Element {
  const {
    isFavorite,
    favoriteAction,
    shouldRenderFavoriteAction,
  }: UseFavoriteActionReturnType = useFavoriteAction({
    entry,
  });

  const { defaultThemeV2Config } = useLoaderData<typeof loader>();

  if (!shouldRenderFavoriteAction) return <></>;

  const selectedActionIcons = {
    ...getCellAssetSources({
      assets,
      assetKey: 'selected_action_icon',
      assetSwitchKey: 'action_switch',
      defaultThemeV2Config,
    }),
  };
  const unselectedActionIcons = {
    ...getCellAssetSources({
      assets,
      assetKey: 'unselected_action_icon',
      assetSwitchKey: 'action_switch',
      defaultThemeV2Config,
    }),
  };

  const imageSources: ResponsiveSources = {
    mobile: isFavorite
      ? selectedActionIcons.mobile
      : unselectedActionIcons.mobile,
    tablet: isFavorite
      ? selectedActionIcons.tablet
      : unselectedActionIcons.tablet,
    desktop: isFavorite
      ? selectedActionIcons.desktop
      : unselectedActionIcons.desktop,
    ['large-desktop']: isFavorite
      ? selectedActionIcons['large-desktop']
      : unselectedActionIcons['large-desktop'],
  };

  try {
    return (
      <button
        onClick={(e: any) => {
          e.preventDefault();
          e.stopPropagation();
          favoriteAction();
        }}
        className={twMerge(
          'pointer-events-auto absolute z-40 mb-cell-action-icon-b ml-cell-action-icon-l mr-cell-action-icon-r mt-cell-action-icon-t opacity-100 action-icon-pos'
        )}
        data-testid={`action-icon-V2-${entry.id}`}
        key={entry.id}
      >
        <ResponsiveImage
          imageSources={imageSources}
          altText={entry?.title && `${entry?.title} Favorite Icon`}
          classNames="h-cell-action-icon w-cell-action-icon"
        />
      </button>
    );
  } catch (error: any) {
    logger.info(`ActionIconV2: ${error.message}`);
    return <></>;
  }
}
