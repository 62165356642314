import type { FeedEntry } from '~/services/layout/index.ts';

//#region Horizontal List Getters
export function getGapWidth({ ref }: any): number {
  if (!ref.current) return 0;
  try {
    return parseInt(getComputedStyle(ref.current)?.gap) || 0;
  } catch (error) {
    return 0;
  }
}
export function getWidth({ ref }: any): number {
  if (!ref?.current) return 0;
  try {
    const gap: number = getGapWidth({ ref });
    return parseInt(getComputedStyle(ref.current)?.width) + gap || 0;
  } catch (error) {
    return 0;
  }
}
export function getPayloadImageHeight({ ref }: any): string {
  if (!ref?.current) return 'auto';
  try {
    const maxHeight = Array.from(
      ref.current.querySelectorAll('[data-payload-image]')
    )
      .map((img: any) => img.getBoundingClientRect().height)
      .reduce(
        (maxHeight: number, currentHeight: number) =>
          Math.max(maxHeight, currentHeight),
        0
      );

    return maxHeight !== 0 ? `${maxHeight}px` : 'auto';
  } catch (error) {
    return 'auto';
  }
}
export function getCellsScrolled({
  ref,
  cellRef,
  countFrom = 'start',
}: {
  ref: any;
  cellRef: any;
  countFrom?: 'start' | 'end';
}): number {
  if (!ref.current) return 0;

  const cellWidth: number = getWidth({ ref: cellRef });
  const visibleCells: number = getVisibleCells({
    ref,
    cellRef,
    screenPadding: 0,
  });
  const scrollLeft: number = getScrollLeft({ ref });
  const scrolled: number = Math.floor(scrollLeft / cellWidth);

  if (scrolled === 0 || scrolled === Infinity) return 0;

  return countFrom === 'start' ? scrolled : scrolled + visibleCells;
}
export function getToggleButtonsState({
  ref,
  isCircularStarted,
  nextFeedUrl,
}: {
  ref: any;
  isCircularStarted?: boolean;
  nextFeedUrl?: string;
}): {
  right: boolean;
  left: boolean;
} {
  if (!ref.current) return { right: false, left: false };

  if (isCircularStarted) return { right: true, left: true };

  const scrollLeft: number = getScrollLeft({ ref });
  const clientWidth: number = getClientWidth({ ref });
  const totalScrollWidth: number = getTotalScrollWidth({ ref });

  const right: boolean = !(scrollLeft >= totalScrollWidth - clientWidth);

  return {
    right: nextFeedUrl ? true : right,
    left: scrollLeft > 0,
  };
}
export function getScrollLeft({ ref }: { ref: any }): number {
  if (!ref.current) return 0;
  return ref.current?.scrollLeft;
}
export function getTotalScrollWidth({ ref }: { ref: any }): number {
  if (!ref.current) return 0;
  return ref.current?.scrollWidth;
}
export function getTotalCells({
  entries,
  isClient,
}: {
  entries: FeedEntry[] | undefined;
  isClient: boolean | undefined;
}): number {
  let entriesLength = entries?.length || 0;

  if (
    entries?.every((obj: Object) => Object.keys(obj)?.length === 0) &&
    isClient
  ) {
    return 0;
  }

  return entriesLength;
}
export function getClientWidth({
  ref,
  screenPadding = 0,
}: {
  ref: any;
  screenPadding?: number;
}): number {
  const clientWidth = ref.current?.clientWidth;
  if (!clientWidth) return 0;
  if (screenPadding > clientWidth) return 0;
  return clientWidth - screenPadding;
}
export function getVisibleCells({
  ref,
  cellRef,
  screenPadding = 0,
}: {
  ref: any;
  cellRef?: any;
  screenPadding?: number;
}): number {
  const clientWidth: number = getClientWidth({
    ref,
    screenPadding,
  });

  const cellWidth: number = getWidth({
    ref: cellRef,
  });

  const visibleCells = Math.floor(clientWidth - screenPadding / cellWidth);

  if (isNaN(visibleCells)) return 0;

  if (visibleCells < 0) return 0;

  return Math.floor((clientWidth - screenPadding) / cellWidth);
}
//#endregion
