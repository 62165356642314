import logger from '~/services/logger';
import type { FeedEntry, UIComponent } from '~/services/layout/index.ts';
import { PowerCell } from '../../power-cell/components';
import { twMerge } from 'tailwind-merge';
import { getPluginConfig } from '~/utils/get-plugin-configuration';
import manifest from '../config/manifest';
import { getTranslations } from '~/services/localization';

export default function EmptyGroupV2({
  uiComponent,
}: {
  uiComponent: UIComponent;
}): JSX.Element {
  try {
    if (uiComponent.component_type !== 'empty_group_v2') return <></>;

    const config = getPluginConfig<typeof manifest>(manifest, uiComponent);

    const translations = getTranslations(uiComponent.localizations);

    const title: string = translations?.empty_group_title || '';
    const summary: string | undefined = translations?.empty_group_summary;

    const key: string | undefined = config?.general?.entry_image_key_name;
    const src: string | undefined = config?.general?.empty_group_image;

    const entry: FeedEntry = {
      id: 'empty_group_entry_id',
      title,
      summary,
      media_group: [
        {
          type: 'image',
          media_item: [
            {
              key,
              src,
            },
          ],
        },
      ],
    };

    return (
      <section
        className={twMerge(
          `component-${uiComponent.id} component-classes component-maximum-width`,
          config?.styles?.use_maximum_component_width && 'ml-auto mr-auto'
        )}
      >
        <PowerCell entry={entry} uiComponent={uiComponent} />
      </section>
    );
  } catch (error: any) {
    logger.info(`EmptyGroupV2: ${error.message}`);
    return <></>;
  }
}
