import type { UIComponent } from '~/services/layout/index.ts';
import { useLoaderData } from '@remix-run/react';
import EmptyGroup from '../empty-group.tsx';
import { getEmptyGroupComponent } from '~/services/layout/layout.ts';
import type { loader as rootLoader } from '~/routes/_index.ts';
import { useGetStorageFeed } from '~/hooks/use-feed.ts';

export default function ClientStorageUiComponent({
  uiComponent,
  Component,
  storageKey,
}: {
  uiComponent: UIComponent;
  Component: (props: any) => JSX.Element;
  storageKey: 'continue-watching' | 'favorites';
}) {
  const {
    isContinueWatchingSyncedFeed,
    isFavoritesSyncedFeed,
    maximumCWentries,
  } = useLoaderData<typeof rootLoader>();

  const syncedFeedsMap = {
    'continue-watching': isContinueWatchingSyncedFeed,
    favorites: isFavoritesSyncedFeed,
  };

  const { feed, feedCount, isLoading, isUpdating } = useGetStorageFeed({
    storageKey,
    condition: syncedFeedsMap[storageKey],
  });

  const emptyGroupComponent: UIComponent | undefined =
    getEmptyGroupComponent(uiComponent);

  if (feedCount === 0 && !emptyGroupComponent && !isLoading) return <></>;

  if (feedCount === 0 && emptyGroupComponent && !isLoading)
    return <EmptyGroup uiComponent={emptyGroupComponent} />;

  if (storageKey === 'continue-watching' && feed?.entry) {
    const isDropTrackingOfOlderEntries: boolean =
      Array.isArray(feed?.entry) && feed.entry.length > maximumCWentries;

    if (isDropTrackingOfOlderEntries)
      feed.entry = feed.entry.slice(0, maximumCWentries);
  }

  return (
    <Component
      uiComponent={uiComponent}
      isClient={true}
      isLoading={isUpdating ? false : isLoading}
      feed={feed}
    />
  );
}
