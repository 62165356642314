import type { FeedEntry, UIComponent } from '~/services/layout';
import { isGroupInfo } from './helpers';
import logger from '~/services/logger';

export function getGroupComponentsAndFeedEntries({
  feed,
  uiComponents,
  uiComponentsLimits,
  initialNumOfComponents,
}: {
  feed: any;
  uiComponents: UIComponent[];
  uiComponentsLimits: number[];
  initialNumOfComponents: number;
}): {
  uiComponent: UIComponent;
  feedEntry: FeedEntry;
}[] {
  try {
    const res: {
      uiComponent: UIComponent;
      feedEntry: FeedEntry;
    }[] = [];

    let isFirstComponent: boolean = true;
    let isDone: boolean = false;
    let entriesAddedCounter: number = 0;

    uiComponents.forEach((c: UIComponent, index: number) => {
      if (isGroupInfo(c)) {
        res.push({
          uiComponent: c,
          feedEntry: feed.entry,
        });
        return;
      }

      if (isDone) return;

      const limit: number = uiComponentsLimits[index];

      if (!limit) {
        res.push({
          uiComponent: c,
          feedEntry: feed.entry,
        });
        isDone = true;
        return;
      }

      entriesAddedCounter += limit;

      if (isFirstComponent || index === 0) {
        res.push({
          uiComponent: c,
          feedEntry: feed.entry.slice(0, limit),
        });
        isFirstComponent = false;
        return;
      }

      if (initialNumOfComponents < index + 1) {
        res.push({
          uiComponent: c,
          feedEntry: feed.entry.slice(
            entriesAddedCounter - limit,
            entriesAddedCounter || undefined
          ),
        });
        return;
      }

      const prevLimit: number = uiComponentsLimits[index - 1];

      res.push({
        uiComponent: c,
        feedEntry: feed.entry.slice(
          prevLimit,
          Number(prevLimit + limit) || undefined
        ),
      });
    });

    return res;
  } catch (error: any) {
    logger.error(`getGroupComponentsAndFeedEntries: ${error.message}`);
    return [];
  }
}
