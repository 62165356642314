import EmptyGroup from '~/components/empty-group.tsx';
import EmptyGroupV2 from '~/services/plugins/empty_group_v2/components/index.tsx';
import { Feed, UIComponent } from '~/services/layout/index.ts';

export const MaybeEmptyGroup = ({
  uiComponent,
  feed,
}: {
  uiComponent?: UIComponent;
  feed?: Feed;
}) => {
  if (feed?.entry && feed?.entry?.length > 0) return <></>;

  return (
    <>
      {uiComponent?.component_type === 'empty_group_component' && (
        <EmptyGroup uiComponent={uiComponent} />
      )}
      {uiComponent?.component_type === 'empty_group_v2' && (
        <EmptyGroupV2 uiComponent={uiComponent} />
      )}
    </>
  );
};
