import logger from '~/services/logger';
import { useRef, useState } from 'react';
import type { Feed, FeedEntry, UIComponent } from '~/services/layout/index.ts';
import { useNextFeedPagination } from '~/hooks/use-next-feed.ts';
import { useFeedEntries } from '~/hooks/use-feed-entries.ts';
import { PowerCell } from '../../power-cell/components';
import manifest from '../config/manifest';
import { getPluginConfig } from '~/utils/get-plugin-configuration';
import { useFeedEntriesOnSearchQueryChange } from '~/hooks/use-feed-entries-on-search';
import { useLastComponentInScreenPaginationCondition } from '~/hooks/use-pagination-condition';
import { useResponsiveValue } from '~/hooks/use-responsive-breakpoint';
import { getGridLastRowHorizontalAlignmentClasses } from './grid-horizontal-align';
import { getUniqueKeyByEntry } from '~/services/layout/layout';

export default function GridV2({
  uiComponent,
  feed,
  isLoading,
  isClient,
}: {
  uiComponent: UIComponent;
  feed?: Feed;
  isLoading?: boolean;
  isClient?: boolean;
}): JSX.Element {
  const { styles, rules } = getPluginConfig<typeof manifest>(
    manifest,
    uiComponent
  );

  const selectable: boolean = !!rules.component_cells_selectable;

  const ref = useRef<any>(null);

  const [feedEntries, setFeedEntries] = useState<FeedEntry[]>([]);

  const [nextFeedUrl, setNextFeedUrl] = useState<string>(feed?.next || '');

  const [isNextFeedLoading, setIsNextFeedLoading] = useState<boolean>(false);

  const itemLimit: number | undefined = rules?.item_limit;

  const entries = useFeedEntries({
    feedEntries,
    setFeedEntries,
    isLoading,
    isClient,
    feed,
    itemLimit,
  });

  useFeedEntriesOnSearchQueryChange({
    setFeedEntries,
    entries,
  });

  const cellContainerRefs = useRef<any>([]);

  const paginationCondition = useLastComponentInScreenPaginationCondition({
    refs: cellContainerRefs,
    isLoading,
    uiComponentId: uiComponent?.id,
  });

  useNextFeedPagination({
    feed,
    nextFeedUrl,
    setNextFeedUrl,
    setIsNextFeedLoading,
    isNextFeedLoading,
    isLoading,
    setFeedEntries,
    feedEntries,
    itemLimit,
    condition: paginationCondition,
  });

  const cols: number = useResponsiveValue(
    {
      mobile: styles?.component_mobile_columns,
      tablet: styles?.component_tablet_columns,
      desktop: styles?.component_desktop_columns,
      largeDesktop: styles?.component_large_desktop_columns,
    },
    false
  );

  const lastRowHorizontalAlignment =
    styles?.component_last_row_horizontal_alignment as
      | 'start'
      | 'center'
      | 'end';

  try {
    return (
      <div className="flex justify-evenly" ref={ref}>
        <section
          className={`
          component-${uiComponent.id} component-classes grid-cols grid h-fit w-full shrink-0 overflow-x-auto overflow-y-hidden border-none scrollbar-hide`}
        >
          {entries?.map((entry: FeedEntry, index: number) => {
            const key: string = getUniqueKeyByEntry('grid-v2', entry, index);
            return (
              <div
                key={key}
                id={key}
                className={getGridLastRowHorizontalAlignmentClasses({
                  index,
                  alignment: lastRowHorizontalAlignment,
                  feedEntryLength: entries.length,
                  cols,
                })}
                ref={(el) => (cellContainerRefs.current[index] = el)}
              >
                <PowerCell
                  uiComponent={uiComponent}
                  entry={entry}
                  selectable={selectable}
                  isLoading={isLoading}
                />
              </div>
            );
          })}
        </section>
      </div>
    );
  } catch (error: any) {
    logger.info(`Grid: ${error.message}`);
    return <></>;
  }
}
