import { useLoaderData } from '@remix-run/react';

import logger from '~/services/logger';
import { Cell } from './cell/index.tsx';
import { getPluginIdentifier } from '~/services/ui-components/index.ts';

import type { UIComponent } from '~/services/layout/index.ts';
import type { loader as rootLoader } from '~/routes/_index.ts';
import { getTranslations } from '~/services/localization/index.ts';

/**
 * empty_group_component is a component that is used to display a message when there is no data to display.
 * @param uiComponent UIComponent
 * @returns JSX.Element | null
 */
export default function EmptyGroup({
  uiComponent,
}: {
  uiComponent: UIComponent;
}): JSX.Element {
  const { cellStyles } = useLoaderData<typeof rootLoader>();

  try {
    const cellId: string = uiComponent?.styles?.cell_plugin_configuration_id;
    const pluginIdentifier: string | undefined = getPluginIdentifier(
      cellStyles,
      cellId
    );

    const translations = getTranslations(uiComponent.localizations);

    const title: string = translations?.empty_group_title || '';
    const summary: string | undefined = translations?.empty_group_summary;

    const key: string | undefined = uiComponent?.general?.entry_image_key_name;
    const src: string | undefined = uiComponent?.general?.empty_group_image;

    if (uiComponent.component_type !== 'empty_group_component') return <></>;

    if (!title && !summary && (!src || !key)) return <></>;

    const entry: EmptyGroupEntry = {
      id: 'empty_group_entry_id',
      title,
      summary,
      media_group: [
        {
          type: 'image',
          media_item: [
            {
              key,
              src,
            },
          ],
        },
      ],
    };

    return (
      <>
        {
          <section
            className={`component-${uiComponent.id} mb-empty-group-b mt-empty-group-t flex whitespace-pre-wrap bg-empty-group-background-color pb-empty-group-b pl-empty-group-l pr-empty-group-r pt-empty-group-t`}
            data-testid={`empty-group-${uiComponent?.id}`}
          >
            <Cell
              pluginIdentifier={pluginIdentifier}
              entry={entry}
              cellId={cellId}
              uiComponent={uiComponent}
            />
          </section>
        }
      </>
    );
  } catch (error: any) {
    logger.info(`EmptyGroup: ${error.message}`);
    return <></>;
  }
}

interface EmptyGroupEntry {
  id: string;
  title: string;
  summary: string | undefined;
  media_group: MediaGroup[];
}

interface MediaGroup {
  type: string;
  media_item: MediaItem[];
}

interface MediaItem {
  key: string | undefined;
  src: string | undefined;
}
