import { useEffect, useRef, useState } from 'react';
import logger from '~/services/logger';

export function useInView({
  refs,
}: {
  refs: React.MutableRefObject<(HTMLElement | null)[]>;
}): boolean {
  const htmlElementsIdsQueue = useRef<string[]>([]);

  const [isIntersecting, setIsIntersecting] = useState<boolean>(false);

  const setLastElementIntersecting = (): void => {
    const lastElement: HTMLElement | null | undefined = refs.current?.at(-1);

    if (!lastElement) return;

    const lastElemenRect: DOMRect = lastElement.getBoundingClientRect();

    const isVisible: boolean =
      lastElemenRect.top - lastElemenRect.height / 2 < window.innerHeight &&
      lastElemenRect.bottom > 0;

    const lastElementId: string | undefined = lastElement?.id;

    if (!lastElementId) {
      logger.error('~ useInView: HTML Element id is not defined');
      return;
    }

    if (isVisible && htmlElementsIdsQueue.current.includes(lastElementId))
      return;

    if (isVisible) htmlElementsIdsQueue.current.push(lastElementId);

    setIsIntersecting(isVisible);
  };

  useEffect(() => {
    const isSupported: boolean = 'IntersectionObserver' in window;

    if (!refs?.current?.length || !isSupported) return;

    const observer = new MutationObserver(setLastElementIntersecting);

    refs.current.forEach((el) => {
      if (!el) return;
      observer.observe(el, {
        childList: true,
        subtree: true,
        attributes: false,
        characterData: false,
      });
    });

    window.addEventListener('scroll', setLastElementIntersecting);
    window.addEventListener('resize', setLastElementIntersecting);

    setLastElementIntersecting();

    return () => {
      observer.disconnect();
      window.removeEventListener('scroll', setLastElementIntersecting);
      window.removeEventListener('resize', setLastElementIntersecting);
    };
  }, [refs.current.length]);

  return isIntersecting;
}
