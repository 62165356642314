import { number } from 'zod';
import logger from '~/services/logger';
import {
  getCellBreakpointValues,
  getCellVisibillityClasses,
} from './power-cell-getters';

/**
 * A progress bar component
 * @param enabled Whether the progress bar should be enabled
 * @param progress The progress of the progress bar (0-1)
 * @returns A JSX progress bar component for continue watching
 */
export function ProgressBarV2({
  progress,
  assets,
}: {
  progress?: number;
  assets: any;
}): JSX.Element {
  try {
    if (!progress) return <></>;

    const progressWidth = number().gte(0).lte(1).parse(progress) * 100;

    return (
      <div
        className={getCellVisibillityClasses(
          getCellBreakpointValues({
            config: assets,
            key: 'progress_bar_switch',
          }),
          'relative z-[4] progress-bar-x-margins'
        )}
      >
        <div className="absolute h-progress-bar w-full rounded-progress-bar-progress bg-progress-bar-total progress-bar-y-margins">
          <div
            className="h-progress-bar rounded-progress-bar-total bg-progress-bar-progress"
            style={{ width: `${progressWidth}%` }}
          />
        </div>
      </div>
    );
  } catch (error: any) {
    logger.info(`ProgressBarV2: ${error?.message}`);
    return <></>;
  }
}
