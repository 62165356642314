import { twMerge } from 'tailwind-merge';
import { ChevronIcon } from '~/components/cell-components/chevron-icon.tsx';
import { getComponentIcons } from '~/utils/get-component-icons.ts';
import {
  getClientWidth,
  getGapWidth,
  getPayloadImageHeight,
  getScrollLeft,
  getVisibleCells,
  getWidth,
} from './getters.ts';
import logger from '~/services/logger';
import type { UIComponent } from '~/services/layout/index.ts';

//#region Horizontal List Button Component
export function HorizontalListButton({
  direction,
  showBtn,
  uiComponent,
  continaerRef: ref,
  cellRef,
  isNextFeedLoading,
  screenPadding,
  setToggleButtons,
}: {
  direction: 'left' | 'right';
  showBtn: boolean;
  uiComponent: UIComponent;
  continaerRef: any;
  cellRef: any;
  isNextFeedLoading: boolean;
  screenPadding: number;
  setToggleButtons: any;
}) {
  const { leftBtnIcon, rightBtnIcon } = getComponentIcons(uiComponent);

  const btnIcon: string | undefined =
    direction === 'left' ? leftBtnIcon : rightBtnIcon;

  const horizontalListBtnId: string = `horizontal-list-${direction}-btn-${uiComponent.id}`;

  const btnIconSizeClassName: string = 'h-12 w-12';

  const payloadImageHeight: string = getPayloadImageHeight({
    ref: cellRef,
  });

  return (
    <>
      <button
        className={twMerge(
          `pointer-events-none absolute z-40 hidden h-full bg-horizontal-list-buttons opacity-0 transition-opacity duration-500 ease-in-out hover:bg-horizontal-list-buttons-hover lg:block`,
          direction === 'left' ? 'left-0' : 'right-0',
          (showBtn || isNextFeedLoading) && 'pointer-events-auto opacity-100',
          !payloadImageHeight && `h-full`
        )}
        style={{
          maxHeight: payloadImageHeight,
        }}
        key={horizontalListBtnId}
        data-testid={horizontalListBtnId}
        aria-disabled={!showBtn}
        onClick={() => {
          try {
            horizontalListButtonOnClick({
              direction,
              ref,
              cellRef,
              screenPadding,
              setToggleButtons,
            });
          } catch (error: any) {
            logger.info(
              `Horizontal list button onClick ${direction}: ${error.message}`
            );
          }
        }}
      >
        <div className={twMerge(isNextFeedLoading && 'opacity-50')}>
          {btnIcon ? (
            <img
              alt={`rail ${direction} button icon`}
              className={btnIconSizeClassName}
              src={btnIcon}
              key={`horizontal-list-${direction}-btn-icon-${uiComponent.id}`}
            />
          ) : (
            <ChevronIcon
              className={btnIconSizeClassName}
              direction={direction}
            />
          )}
        </div>
      </button>
    </>
  );
}

export function horizontalListButtonOnClick({
  direction,
  ref,
  cellRef,
  screenPadding,
  setToggleButtons,
}: {
  direction: 'left' | 'right';
  ref: any;
  cellRef: any;
  screenPadding: number;
  setToggleButtons: any;
}) {
  if (!ref?.current) return;

  const gap: number = getGapWidth({
    ref,
  });

  const clientWidth: number = getClientWidth({
    ref,
  });

  const visibleCells: number = getVisibleCells({
    ref,
    cellRef,
    screenPadding,
  });

  const scrollLeft: number = getScrollLeft({ ref });

  const scrollRect: number = gap * visibleCells + clientWidth;

  const cellWidth: number = getWidth({
    ref: cellRef,
  });

  const left: number =
    direction === 'right'
      ? scrollLeft + scrollRect - cellWidth
      : scrollLeft - scrollRect + cellWidth;

  ref.current.scrollTo({
    left,
    behavior: 'smooth',
  });

  setToggleButtons((prev: any) => ({
    ...prev,
    left: direction === 'right' ? true : prev.left,
    right: direction === 'left' ? true : prev.right,
    now: new Date().getTime(),
  }));
}

//#endregion
