import React, { useEffect, useState } from 'react';
import { useLoaderData, useNavigation } from '@remix-run/react';
import { twMerge } from 'tailwind-merge';

import logger from '~/services/logger';
import { Cell } from '~/components/cell/index.tsx';
import { getPluginIdentifier } from '~/services/ui-components/index.ts';
import {
  HeroCarousel,
  getHeroCarouselSettings,
  getHeroCarouselUtils,
  maxHeroCarouselLimit,
} from './hero-carousel.tsx';
import { getLimit } from '~/services/layout/layout.ts';
import type { Feed, FeedEntry, UIComponent } from '~/services/layout/index.ts';
import type { loader as rootLoader } from '~/routes/_index.tsx';
import manifest from '../config/manifest.ts';
import { getPluginConfig } from '~/utils/get-plugin-configuration.ts';

type Props = {
  uiComponent: UIComponent;
  feed?: Feed;
  isLoading?: boolean;
};

export default function Hero({
  uiComponent,
  feed,
  isLoading,
}: Props): JSX.Element {
  const { cellStyles } = useLoaderData<typeof rootLoader>();
  const navigation = useNavigation();

  const config = getPluginConfig<typeof manifest>(manifest, uiComponent);

  const cellId: string = uiComponent?.styles?.cell_plugin_configuration_id;
  const selectable = !!config.rules.component_cells_selectable;

  const pluginIdentifier: string | undefined = getPluginIdentifier(
    cellStyles,
    cellId
  );
  const feedPath: string | undefined = feed?._feedPath;

  const { isCarouselEnabled, isCarouselAutoplay, carouselAutoplaySpeed } =
    getHeroCarouselSettings(
      uiComponent,
      config.styles?.hero_carousel_switch as boolean,
      config.styles?.hero_carousel_autoplay_switch as boolean,
      config.styles?.hero_carousel_autoplay_speed as number
      // config.styles.hero_carousel_autoplay_pause_on_hover as boolean
    );

  const [showEntries, setShowEntries] = useState<boolean[]>([]);
  const [currentEntryIndex, setCurrentEntryIndex] = useState<number>(0);

  const [isCarouselEntries, setIsCarouselEntries] = useState<boolean>(false);

  const feedEntryLength: number = getHeroFeedEntryLength({
    feed: feed as Feed,
    isCarouselEnabled,
    itemLimit: config.rules.item_limit as string | number,
  });

  const firstEntry: FeedEntry | undefined = feed?.entry?.[0];

  const { showEntry, showNextEntry, showPrevEntry } = getHeroCarouselUtils({
    feed,
    feedEntryLength,
    currentEntryIndex,
    setCurrentEntryIndex,
    setShowEntries,
  });

  useEffect(() => {
    showEntry(0);
  }, []);

  // Carousel autoplay
  useEffect(() => {
    if (feedEntryLength < 2) return;

    if (currentEntryIndex !== 0 && !isCarouselEntries)
      setIsCarouselEntries(true);

    if (!isCarouselAutoplay) return;

    if (navigation.state === 'loading') return;

    const autoplayInterval = setInterval(() => {
      showNextEntry();
    }, carouselAutoplaySpeed * 1000);

    return () => {
      clearInterval(autoplayInterval);
    };
  }, [currentEntryIndex, navigation.state]);

  if (!firstEntry || feedEntryLength < 1) return <></>;

  try {
    return (
      <section
        className={`component-${uiComponent.id} relative
            mb-component-b ml-component-l
            mr-component-r mt-component-t h-fit w-full overflow-hidden border-none bg-component pb-component-b pl-component-l pr-component-r pt-component-t transition-opacity
            `}
      >
        {!isCarouselEntries && currentEntryIndex === 0 ? (
          <Cell
            pluginIdentifier={pluginIdentifier}
            entry={firstEntry}
            cellId={cellId}
            feedPath={feedPath}
            isLoading={isLoading}
            selectable={selectable}
            uiComponent={uiComponent}
          />
        ) : (
          React.Children.toArray(
            feed?.entry.map((entry, index) => {
              return (
                <div
                  className={twMerge(
                    `transition-opacity hero-carousel-transition-duration`,
                    showEntries[index]
                      ? 'opacity-100'
                      : 'pointer-events-none fixed opacity-0'
                  )}
                  key={`${index}-${uiComponent.id}`}
                >
                  {showEntries[index] && (
                    <Cell
                      pluginIdentifier={pluginIdentifier}
                      entry={entry}
                      cellId={cellId}
                      feedPath={feedPath}
                      isLoading={isLoading}
                      selectable={selectable}
                      uiComponent={uiComponent}
                    />
                  )}
                </div>
              );
            })
          )
        )}
        {showEntries?.some((entry) => entry) && (
          <HeroCarousel
            feed={feed}
            feedEntryLength={feedEntryLength}
            currentEntryIndex={currentEntryIndex}
            showNextEntry={showNextEntry}
            showPrevEntry={showPrevEntry}
            showEntry={showEntry}
            uiComponent={uiComponent}
          />
        )}
      </section>
    );
  } catch (error: any) {
    logger.info(`Hero: ${error.message}`);
    return <></>;
  }
}

function getHeroFeedEntryLength({
  feed,
  isCarouselEnabled,
  itemLimit,
}: {
  feed: Feed;
  isCarouselEnabled: boolean;
  itemLimit: string | number;
}): number {
  const isFeedEntries: boolean = !!feed?.entry?.length;

  if (!isFeedEntries) return 0;

  if (!isCarouselEnabled) return 1;

  const limit = getLimit(itemLimit);

  if ((limit === 1 || limit === 0) && feed!.entry.length === 1) return 1;

  const calculatedLimit: number =
    typeof limit === 'number' && limit < maxHeroCarouselLimit && limit > 0
      ? limit
      : maxHeroCarouselLimit;

  return Math.min(feed!.entry.length, calculatedLimit);
}
