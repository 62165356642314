import logger from '~/services/logger';

/**
 * Get the CSS classes for the horizontal alignment of the last row of the grid
 * If the alignment is 'start', return an empty string.
 * If the alignment is 'end', return col-start-<Number>.
 * If the alignment is 'center', indicate if the positioning is evenly possible or
 * not and return the relevant classes.
 * @param index - The index of the current cell in the grid
 * @param alignment - The horizontal alignment for the last row of the grid
 * @param feedEntryLength - The total number of cells in the grid
 * @param cols - The number of columns in the grid (Responsive)
 * @returns The Classes or an empty string
 */
export function getGridLastRowHorizontalAlignmentClasses({
  index,
  alignment,
  feedEntryLength,
  cols,
}: {
  index: number;
  alignment: 'start' | 'center' | 'end';
  feedEntryLength: number;
  cols: number;
}): string {
  try {
    if (alignment === 'start') return '';

    const lastRowCellsCount: number = feedEntryLength % cols || cols;

    const isLastRow: boolean = index >= feedEntryLength - lastRowCellsCount;

    if (!isLastRow) return '';

    const lastRowStartIndex: number = feedEntryLength - lastRowCellsCount;

    const isLastRowStartIndex: boolean = index === lastRowStartIndex;

    if (alignment === 'end' && isLastRowStartIndex) {
      return `col-start-${Math.ceil(cols - lastRowCellsCount) + 1}`;
    }

    if (alignment !== 'center') return '';

    const isEvenCenterPosition: boolean = (cols - lastRowCellsCount) % 2 === 0;

    const colStart: number = Math.ceil(
      isEvenCenterPosition
        ? (cols - lastRowCellsCount) / 2 + 1
        : (cols - lastRowCellsCount) / 2
    );

    if (isEvenCenterPosition && isLastRowStartIndex)
      return `col-start-${colStart}`;

    if (index < lastRowStartIndex || isEvenCenterPosition) return '';

    const iterableColStart: number = isLastRowStartIndex
      ? colStart
      : colStart + (index - lastRowStartIndex);

    const rowStart: number = Math.ceil(feedEntryLength / cols);

    return `col-span-2 col-start-${iterableColStart} row-start-${rowStart} place-self-center w-[50%] grid-last-row`;
  } catch (error: any) {
    logger.info(`getGridLastRowHorizontalAlignmentClasses: ${error.message}`);

    return '';
  }
}
