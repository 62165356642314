import { twMerge } from 'tailwind-merge';
import {
  getCellBreakpointValues,
  getCellVisibillityClasses,
  type ResponsiveBooleans,
  type ResponsiveSources,
} from './power-cell-getters';
import { ResponsiveImage } from './responsive-image';

export function CellBadge({
  assets,
  isHoverBadge,
}: {
  assets: any;
  isHoverBadge: boolean;
}): JSX.Element {
  let {
    cellBadgeSwitches,
    cellBadgeImageSources,
    cellHoverBadgeSwitches,
    cellHoverBadgeImageSources,
    componentVisibilityClasses,
  } = getCellBadgeData(assets);

  if (!isHoverBadge) {
    cellHoverBadgeSwitches = {
      mobile: false,
      tablet: false,
      desktop: false,
      'large-desktop': false,
    };
  }

  return (
    <div
      className={getCellVisibillityClasses(
        componentVisibilityClasses,
        'ml-cell-badge-l mr-cell-badge-r flex items-center'
      )}
    >
      <ResponsiveImage
        imageSources={cellBadgeImageSources}
        altText={'cell badge'}
        classNames={`z-[3] w-cell-badge-width h-cell-badge-height block ${getCellHoverBadgeHiddenGroupClasses(
          cellHoverBadgeSwitches
        )} ${getCellVisibillityClasses(cellBadgeSwitches)}`}
      />
      <ResponsiveImage
        imageSources={cellHoverBadgeImageSources}
        altText={'cell hover badge'}
        classNames={`z-[3] w-cell-badge-width h-cell-badge-height hidden ${getCellHoverBadgeBlockGroupClasses(
          cellHoverBadgeSwitches
        )} ${getCellVisibillityClasses(cellHoverBadgeSwitches)}`}
      />
    </div>
  );
}

function getCellHoverBadgeHiddenGroupClasses(
  cellHoverBadgeSwitch: ResponsiveSources
) {
  return twMerge(
    cellHoverBadgeSwitch.mobile &&
      `mobile:max-tablet:group-hover-custom:hidden`,
    cellHoverBadgeSwitch.tablet &&
      `tablet:max-desktop:group-hover-custom:hidden`,
    cellHoverBadgeSwitch.desktop &&
      `desktop:max-large-desktop:group-hover-custom:hidden`,
    cellHoverBadgeSwitch['large-desktop'] &&
      `large-desktop:group-hover-custom:hidden`
  );
}

function getCellHoverBadgeBlockGroupClasses(
  cellHoverBadgeSwitch: ResponsiveSources
) {
  return twMerge(
    cellHoverBadgeSwitch.mobile && `mobile:max-tablet:group-hover-custom:block`,
    cellHoverBadgeSwitch.tablet &&
      `tablet:max-desktop:group-hover-custom:block`,
    cellHoverBadgeSwitch.desktop &&
      `desktop:max-large-desktop:group-hover-custom:block`,
    cellHoverBadgeSwitch['large-desktop'] &&
      `large-desktop:group-hover-custom:block`
  );
}

function getCellBadgeData(assets: any): {
  cellBadgeSwitches: ResponsiveBooleans;
  cellBadgeImageSources: ResponsiveSources;
  cellHoverBadgeSwitches: ResponsiveBooleans;
  cellHoverBadgeImageSources: ResponsiveSources;
  componentVisibilityClasses: ResponsiveBooleans;
} {
  const cellBadgeSwitch: ResponsiveSources = getCellBreakpointValues({
    config: assets,
    key: 'cell_badge_switch',
  });

  const cellBadgeImageSources: ResponsiveSources = getCellBreakpointValues({
    config: assets,
    key: 'cell_badge',
  });

  const cellBadgeSwitches: ResponsiveBooleans = {
    mobile: !!(cellBadgeSwitch.mobile && cellBadgeImageSources.mobile),
    tablet: !!(cellBadgeSwitch.tablet && cellBadgeImageSources.tablet),
    desktop: !!(cellBadgeSwitch.desktop && cellBadgeImageSources.desktop),
    'large-desktop': !!(
      cellBadgeSwitch['large-desktop'] && cellBadgeImageSources['large-desktop']
    ),
  };

  const cellHoverBadgeSwitch: ResponsiveSources = getCellBreakpointValues({
    config: assets,
    key: 'cell_hover_badge_switch',
  });

  const cellHoverBadgeImageSources: ResponsiveSources = getCellBreakpointValues(
    {
      config: assets,
      key: 'cell_hover_badge',
    }
  );

  const cellHoverBadgeSwitches: ResponsiveBooleans = {
    mobile: !!(
      cellHoverBadgeSwitch.mobile && cellHoverBadgeImageSources.mobile
    ),
    tablet: !!(
      cellHoverBadgeSwitch.tablet && cellHoverBadgeImageSources.tablet
    ),
    desktop: !!(
      cellHoverBadgeSwitch.desktop && cellHoverBadgeImageSources.desktop
    ),
    'large-desktop': !!(
      cellHoverBadgeSwitch['large-desktop'] &&
      cellHoverBadgeImageSources['large-desktop']
    ),
  };

  const componentVisibilityClasses = {
    mobile: cellBadgeSwitches.mobile || cellHoverBadgeSwitches.mobile,
    tablet: cellBadgeSwitches.tablet || cellHoverBadgeSwitches.tablet,
    desktop: cellBadgeSwitches.desktop || cellHoverBadgeSwitches.desktop,
    'large-desktop':
      cellBadgeSwitches['large-desktop'] ||
      cellHoverBadgeSwitches['large-desktop'],
  };

  return {
    cellBadgeSwitches,
    cellBadgeImageSources,

    cellHoverBadgeSwitches,
    cellHoverBadgeImageSources,

    componentVisibilityClasses,
  };
}
